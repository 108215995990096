<template>
  <b-container class="pt-5" fluid="lg">
    <b-form-row>
      <b-col md="10" >
        <b-alert :show="message !=''" variant="danger" class="text-center">{{message}}</b-alert>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!editMode && !assistantMode && !creationMode">
      <b-col md="3" sm="2" >
        <b-form-group id="mode-grp" label="Mode" label-for="mode">
          <b-form-select id="mode" v-model="mode" :options="modeList" @change="onMode()"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3" align-self="center" class="mt-3">
        <b-button @click="resetVT()"> Nouveau dossier</b-button>
      </b-col>
    </b-form-row>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <!-- Tabs with card integration -->
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset" v-if="mode != null || editMode || assistantMode || creationMode">
    <b-card no-body>
      <b-tabs v-model="tabIndex" small card pills>
        <b-tab title="Mes informations" :title-link-class="linkClass('primary')" @click="selectedTab='primary'"  v-if="!editMode">
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="6">
              <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom"></TextInput>
            </b-col>
            <b-col md="5">
              <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom"></TextInput>
            </b-col>
            <b-col style="align-self:center; padding-top:15px">
              <b-button variant="primary" @click="handleConjoint()" style="width:38px;height:38px">{{ existeConjoint ? "-" : "+"}}</b-button>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode" v-show="existeConjoint">
            <b-col md="6">
              <TextInput id="nom_conjoint" type="text" label="Nom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.nom_conjoint"></TextInput>
            </b-col>
            <b-col md="6">
              <TextInput id="prenom_conjoint" type="text" label="Prénom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.prenom_conjoint"></TextInput>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="12">
              <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">            
            <b-col md="3">
              <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.codePostal"></TextInput>             
            </b-col>
            <b-col md="6">
              <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="6">
              <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.telephone"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.mobile"></TextInput>                 
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode == '7' || assistantMode || creationMode">
            <b-col md="12">
                <h3>Informations fiscales</h3>
            </b-col>
          </b-form-row >
            <b-form-row  v-if="mode == '7'|| assistantMode || creationMode">
            <b-col md="12">
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th sticky-column></b-th>
                      <b-th>Foyer fiscal 1</b-th>
                      <b-th>Foyer fiscal 2</b-th>
                      <b-th>Foyer fiscal 3</b-th>
                      <b-th>Total</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th sticky-column>Revenu fiscal</b-th>
                      <b-td><TextInput id="revenu" type="number" label="" :rules="{ required: true}" v-model.number="informations.revenu1"></TextInput></b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu2"></TextInput> </b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu3"></TextInput></b-td>
                      <b-td>{{ totalRevenu}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column>Nombre de personnes</b-th>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" :rules="{ required: true}" v-model.number="informations.nbPersonnes1"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes2"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes3"></TextInput></b-td>
                      <b-td>{{ totalPersonnes}}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </b-col>
          </b-form-row>
          <b-form-row v-if="(!assistantMode || creationMode) && showTabs" class="mb-2">
            <b-col md="12">
              <b-card-group deck  class="mt-3">
                <b-card border-variant="secondary" header="Catégorie MPR" :body-class="categorieColor" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieMPR}} - {{categorieColor}}</b-card-text>
                </b-card>

                <b-card border-variant="secondary" header="Catégorie CEE" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieCEE}}</b-card-text>
                </b-card>
              <b-card border-variant="secondary" header="Zone Géo" header-border-variant="secondary" align="center">
                  <b-card-text>
                     {{informations.zoneGeo}}
                  </b-card-text>
                </b-card>
            </b-card-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <div v-if="showTabs">
        <!-- Produits -->
        <b-tab :title="blocs[10].title" :title-link-class="linkClass(blocs[10].id)" @click="selectedTab=blocs[10].id" v-if="(!editMode && mode =='7') || assistantMode || creationMode">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="oblige-grp" label="Choisir un obligé par défaut" label-for="oblige">
                <b-form-select id="oblige" v-model="oblige" :options="obligeList"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4">
              <b-form-group id="produitReco-grp" :label="blocs[10].questions[0].title" :label-for="blocs[10].questions[0].comment">
                <b-form-checkbox-group :id="blocs[10].questions[0].comment" v-model="blocs[10].questions[0].value" :options="blocs[10].questions[0].list_values" stacked></b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h5>Fabricants par défaut</h5>
              <b-form-group v-for="fabricant in fabricantsDefaut" :key="fabricant.id" id="produitDef-grp" :label="fabricant.label">
                <b-form-select :id="fabricant.id" v-model="fabricant.value"  @change="updateFabricantDefaut()">
                  <!--b-form-select-option  v-for="option in fabricant.options" :key="option.value" :value="option.value">{{option.text}}</b-form-select-option-->
                  <option  v-for="option in fabricant.options" :key="option.value" :value="option.value" :selected="option.selected">{{option.text}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>  
        </b-tab> 
        <!-- Maison -->
        <b-tab :title="blocs[0].title" :title-link-class="linkClass(blocs[0].id)"  @click="selectedTab=blocs[0].id">
          <b-form-row v-if="mode =='10'">
            <b-col md="12">
                <h3>{{blocs[0].questions[37].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode !='pv' || mode == '10'">
            <b-col md="6">
              <!-- <b-form-group id="superficie-grp" label="Quel est la superficie de la maison ?" label-for="superficie">
                <b-form-input id="superficie" type="number" v-model="superficie" number></b-form-input>
              </b-form-group> -->
              <b-form-group id="superficie-grp" :label="blocs[0].questions[1].title" :label-for="blocs[0].questions[1].comment">
                <b-form-input :id="blocs[0].questions[1].comment" :type="blocs[0].questions[1].value_type" v-model="blocs[0].questions[1].value" number></b-form-input>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="sup-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="anneeMaison-grp" :label="blocs[0].questions[0].title" :label-for="blocs[0].questions[0].comment">
                <b-form-select :id="blocs[0].questions[0].comment" v-model="blocs[0].questions[0].value" :options="blocs[0].questions[0].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="annee-hlp" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="12">
              <h3>{{blocs[0].questions[36].title}}</h3>
            </b-col>
          </b-form-row>     
          <!-- <b-form-row v-if="mode !='pv'">
            
            <b-col md="6">
              <b-form-group id="preciserAnneeMaison-grp" :label="blocs[0].questions[42].title" :label-for="blocs[0].questions[42].comment">
                <b-form-select :id="blocs[0].questions[42].comment" v-model="blocs[0].questions[42].value" :options="blocs[0].questions[42].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row> -->
          <b-form-row>       
            <b-col md="6">
              <b-form-group id="dispositionMaison-grp" :label="blocs[0].questions[2].title" :label-for="blocs[0].questions[2].comment">
                <b-form-radio-group :id="blocs[0].questions[2].comment" v-model="blocs[0].questions[2].value" name="radio-sub-component15" :options="blocs[0].questions[2].list_values">
                <b-form-text v-if="modelVT.produits.includes('VMC')" id="disposition-help" text-variant="danger">Champs requis</b-form-text>
                </b-form-radio-group>           
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="combles-grp" :label="blocs[0].questions[3].title" :label-for="blocs[0].questions[3].comment">
                <b-form-select :id="blocs[0].questions[3].comment" v-model="blocs[0].questions[3].value" :options="blocs[0].questions[3].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('VMC')" id="combles-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <!-- <b-col md="3">
              <b-form-group id="numPieces-grp" label="Quelle est le nombre de pièces ?" label-for="numPieces">
                <b-form-input id="numPieces" type="number" v-model.number="numPieces" ></b-form-input>
              </b-form-group>
            </b-col>      
              -->
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
              <h3>{{blocs[0].questions[43].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4">
              <b-form-group id="deperdition" :label="blocs[0].questions[44].title" :label-for="blocs[0].questions[44].comment">
                <b-form-select :id="blocs[0].questions[44].comment" v-model="blocs[0].questions[44].value" :options="blocs[0].questions[44].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="volume" :label="blocs[0].questions[45].title" :label-for="blocs[0].questions[45].comment">
                <b-input-group append="M3">
                  <b-form-input :id="blocs[0].questions[45].comment" :type="blocs[0].questions[45].value_type" v-model="blocs[0].questions[45].value" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="altitude" :label="blocs[0].questions[46].title" :label-for="blocs[0].questions[46].comment">
                <b-input-group append="M">
                  <b-form-input :id="blocs[0].questions[46].comment" :type="blocs[0].questions[46].value_type" v-model="blocs[0].questions[46].value" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group id="P" :label="blocs[0].questions[47].title" :label-for="blocs[0].questions[47].comment">
                <b-form-input  readonly :id="blocs[0].questions[47].comment" :type="blocs[0].questions[47].value_type" v-model="blocs[0].questions[47].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row> 
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="occupation-grp" :label="blocs[0].questions[26].title" :label-for="blocs[0].questions[26].comment">
                <b-form-select :id="blocs[0].questions[26].comment" v-model="blocs[0].questions[26].value" :options="blocs[0].questions[26].list_values"></b-form-select>
              </b-form-group>
            </b-col>      
            <!-- <b-col md="6">
              <b-form-group id="habitation-grp" :label="blocs[0].questions[27].title" :label-for="blocs[0].questions[27].comment">
                <b-form-select :id="blocs[0].questions[27].comment" v-model="blocs[0].questions[27].value" :options="blocs[0].questions[27].list_values"></b-form-select>
              </b-form-group>
            </b-col> -->
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="confortEte-grp" :label="blocs[0].questions[28].title" :label-for="blocs[0].questions[28].comment">
                <b-form-select :id="blocs[0].questions[28].comment" v-model="blocs[0].questions[28].value" :options="blocs[0].questions[28].list_values"></b-form-select>
              </b-form-group>
            </b-col>      
            <b-col md="6">
              <b-form-group id="confortHiver-grp" :label="blocs[0].questions[29].title" :label-for="blocs[0].questions[29].comment">
                <b-form-select :id="blocs[0].questions[29].comment" v-model="blocs[0].questions[29].value" :options="blocs[0].questions[29].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="formeLogement-grp" :label="blocs[0].questions[7].title" :label-for="blocs[0].questions[7].comment">
                <b-form-select :id="blocs[0].questions[7].comment" v-model="blocs[0].questions[7].value" :options="blocs[0].questions[7].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="niveauChauffes-grp" :label="blocs[0].questions[4].title" :label-for="blocs[0].questions[4].comment">
                <b-form-select :id="blocs[0].questions[4].comment" v-model="blocs[0].questions[4].value" :options="blocs[0].questions[4].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="plancherHaut-grp" :label="blocs[0].questions[5].title" :label-for="blocs[0].questions[5].comment">
                <b-form-select :id="blocs[0].questions[5].comment" v-model="blocs[0].questions[5].value" :options="blocs[0].questions[5].list_values"></b-form-select>
              </b-form-group>
            </b-col>    
            <b-col md="6">
              <b-form-group id="isoPlancherHaut-grp" :label="blocs[0].questions[30].title" :label-for="blocs[0].questions[30].comment">
                <b-form-select :id="blocs[0].questions[30].comment" v-model="blocs[0].questions[30].value" :options="blocs[0].questions[30].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="epaisseurPlancherHaut-grp" :label="blocs[0].questions[31].title" :label-for="blocs[0].questions[31].comment">
                <b-form-input :id="blocs[0].questions[31].comment" v-model="blocs[0].questions[31].value" :type="blocs[0].questions[31].value_type"></b-form-input>
              </b-form-group>
            </b-col>    
            <b-col md="6">
              <b-form-group id="anneeIsoPlancherHaut-grp" :label="blocs[0].questions[32].title" :label-for="blocs[0].questions[32].comment">
                <b-form-input :id="blocs[0].questions[32].comment" :type="blocs[0].questions[32].value_type" v-model="blocs[0].questions[32].value" ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="plancherBas-grp" :label="blocs[0].questions[6].title" :label-for="blocs[0].questions[6].comment">
                <b-form-select :id="blocs[0].questions[6].comment" v-model="blocs[0].questions[6].value" :options="blocs[0].questions[6].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          <b-col md="6" v-if="blocs[0].questions[33]">
              <b-form-group id="isoPlancherBas-grp" :label="blocs[0].questions[33].title" :label-for="blocs[0].questions[33].comment">
                <b-form-select :id="blocs[0].questions[33].comment" v-model="blocs[0].questions[33].value" :options="blocs[0].questions[33].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="epaisseurPlancherBas-grp" :label="blocs[0].questions[34].title" :label-for="blocs[0].questions[34].comment">
                <b-form-input :id="blocs[0].questions[34].comment" v-model="blocs[0].questions[34].value" :type="blocs[0].questions[34].value_type"></b-form-input>
              </b-form-group>
            </b-col>    
            <b-col md="6">
              <b-form-group id="anneeIsoPlancherBas-grp" :label="blocs[0].questions[35].title" :label-for="blocs[0].questions[35].comment">
                <b-form-input :id="blocs[0].questions[35].comment" :type="blocs[0].questions[35].value_type" v-model="blocs[0].questions[35].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="orientationFacade-grp" :label="blocs[0].questions[8].title" :label-for="blocs[0].questions[8].comment">
                <b-form-select :id="blocs[0].questions[8].comment" v-model="blocs[0].questions[8].value" :options="blocs[0].questions[8].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="12">
              <h4>{{blocs[0].questions[38].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">  
            <b-col md="6">
              <b-form-group id="materiau-grp" :label="blocs[0].questions[22].title" :label-for="blocs[0].questions[22].comment">
                <b-form-select :id="blocs[0].questions[22].comment" v-model="blocs[0].questions[22].value" :options="blocs[0].questions[22].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-if="blocs[0].questions[22].value==blocs[0].questions[22].sub_questions[0].selected_parent_value" id="autreMateriau-grp" :label="blocs[0].questions[22].sub_questions[0].title" :label-for="blocs[0].questions[22].sub_questions[0].comment">
                <b-form-input :id="blocs[0].questions[22].sub_questions[0].comment" :type="blocs[0].questions[22].sub_questions[0].value_type" v-model="blocs[0].questions[22].sub_questions[0].value"></b-form-input> 
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">  
            <b-col md="6">
              <b-form-group id="isolation-grp" :label="blocs[0].questions[23].title" :label-for="blocs[0].questions[23].comment">
                <b-form-select :id="blocs[0].questions[23].comment" v-model="blocs[0].questions[23].value" :options="blocs[0].questions[23].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">  
            <b-col md="6">
              <b-form-group id="ouverture-grp" :label="blocs[0].questions[24].title" :label-for="blocs[0].questions[24].comment">
                <b-form-select :id="blocs[0].questions[24].comment" v-model="blocs[0].questions[24].value" :options="blocs[0].questions[24].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="vitrage-grp" :label="blocs[0].questions[25].title" :label-for="blocs[0].questions[25].comment">
                <b-form-select :id="blocs[0].questions[25].comment" v-model="blocs[0].questions[25].value" :options="blocs[0].questions[25].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="12">
                <h4>{{blocs[0].questions[39].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="nord-grp" :label="blocs[0].questions[9].title" :label-for="blocs[0].questions[9].comment">
                <b-form-select :id="blocs[0].questions[9].comment" v-model="blocs[0].questions[9].value" :options="blocs[0].questions[9].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
            <b-form-group id="est-grp" :label="blocs[0].questions[10].title" :label-for="blocs[0].questions[10].comment">
                <b-form-select :id="blocs[0].questions[10].comment" v-model="blocs[0].questions[10].value" :options="blocs[0].questions[10].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="ouest-grp" :label="blocs[0].questions[11].title" :label-for="blocs[0].questions[11].comment">
                <b-form-select :id="blocs[0].questions[11].comment" v-model="blocs[0].questions[11].value" :options="blocs[0].questions[11].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          <b-col md="6">
              <b-form-group id="sud-grp" :label="blocs[0].questions[12].title" :label-for="blocs[0].questions[12].comment">
                <b-form-select :id="blocs[0].questions[12].comment" v-model="blocs[0].questions[12].value" :options="blocs[0].questions[12].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="12">
                <h4>{{blocs[0].questions[40].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="masqueNord-grp" :label="blocs[0].questions[13].title" :label-for="blocs[0].questions[13].comment">
                <b-form-select :id="blocs[0].questions[13].comment" v-model="blocs[0].questions[13].value" :options="blocs[0].questions[13].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="masqueEst-grp" :label="blocs[0].questions[14].title" :label-for="blocs[0].questions[14].comment">
                <b-form-select :id="blocs[0].questions[14].comment" v-model="blocs[0].questions[14].value" :options="blocs[0].questions[14].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="6">
              <b-form-group id="masqueOuest-grp" :label="blocs[0].questions[15].title" :label-for="blocs[0].questions[15].comment">
                <b-form-select :id="blocs[0].questions[15].comment" v-model="blocs[0].questions[15].value" :options="blocs[0].questions[15].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="masqueSud-grp" :label="blocs[0].questions[16].title" :label-for="blocs[0].questions[16].comment">
                <b-form-select :id="blocs[0].questions[16].comment" v-model="blocs[0].questions[16].value" :options="blocs[0].questions[16].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="12">
              <h4>{{blocs[0].questions[41].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">       
            <b-col md="3">
              <b-form-group id="fenetre-grp" :label="blocs[0].questions[17].title" :label-for="blocs[0].questions[17].comment">
                <b-form-input :id="blocs[0].questions[17].comment" :type="blocs[0].questions[17].value_type" v-model="blocs[0].questions[17].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="baie-grp" :label="blocs[0].questions[18].title" :label-for="blocs[0].questions[18].comment">
                <b-form-input :id="blocs[0].questions[18].comment" :type="blocs[0].questions[18].value_type" v-model="blocs[0].questions[18].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="portefenetre-grp" :label="blocs[0].questions[19].title" :label-for="blocs[0].questions[19].comment">
                <b-form-input :id="blocs[0].questions[19].comment" :type="blocs[0].questions[19].value_type" v-model="blocs[0].questions[19].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="fenetreToit-grp" :label="blocs[0].questions[20].title" :label-for="blocs[0].questions[20].comment">
                <b-form-input :id="blocs[0].questions[20].comment" :type="blocs[0].questions[20].value_type" v-model="blocs[0].questions[20].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group id="porte-grp" :label="blocs[0].questions[21].title" :label-for="blocs[0].questions[21].comment">
                <b-form-input :id="blocs[0].questions[21].comment" :type="blocs[0].questions[21].value_type" v-model="blocs[0].questions[21].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- <b-form-row>
            <b-col md="3">
              <b-form-group id="garage-grp" label="Y'a-t-il un garage ?" label-for="garage">
                <b-form-select id="garage" v-model="garage" :options="choixBinaireList"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="garage=='Oui'" id="garageIsole-grp" label="Est-il isolé ?" label-for="garageIsole">
                <b-form-select id="garageIsole" v-model="garageIsole" :options="choixBinaireList"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row> -->
        </b-tab>
        <!-- Toiture -->
        <b-tab :title="blocs[1].title" :title-link-class="modelVT.produits.includes('PV') ? linkClass(blocs[1].id) : ['bg-light', 'text-dark']"  @click="selectedTab=blocs[1].id" :disabled="!modelVT.produits.includes('PV')">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="lieuPPV-grp" :label="blocs[1].questions[0].title" :label-for="blocs[1].questions[0].comment">
                <b-form-radio-group :id="blocs[1].questions[0].comment" v-model="blocs[1].questions[0].value" name="radio-sub-component5" :options="blocs[1].questions[0].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            </b-form-row>
            <b-form-row v-if="blocs[1].questions[0].value==blocs[1].questions[0].sub_questions[1].selected_parent_value">  
            <b-col md="6">
              <b-form-group v-if="blocs[1].questions[0].value==blocs[1].questions[0].sub_questions[0].selected_parent_value" id="precisezAnnexe-grp" :label="blocs[1].questions[0].sub_questions[0].title" :label-for="blocs[1].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[0].sub_questions[0].comment" :type="blocs[1].questions[0].sub_questions[0].value_type" v-model="blocs[1].questions[0].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="distanceAnnexeMaison-grp" 
              :label="blocs[1].questions[0].sub_questions[1].title" :label-for="blocs[1].questions[0].sub_questions[1].comment">
                <b-form-input :id="blocs[1].questions[0].sub_questions[1].comment" :type="blocs[1].questions[0].sub_questions[1].value_type" v-model="distanceAnnexeMaison" number></b-form-input>
              </b-form-group>
            </b-col>
            </b-form-row>
            <b-form-row>  
            <b-col md="6">
              <b-form-group :label="blocs[1].questions[1].title">
                <b-form-radio-group id="radio-group-6" v-model="blocs[1].questions[1].value" name="radio-sub-component6" :options="blocs[1].questions[1].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="superficieToit-grp" :label="blocs[1].questions[2].title" :label-for="blocs[1].questions[2].comment">
                <b-form-input :id="blocs[1].questions[2].comment" :type="blocs[1].questions[2].value_type" v-model="blocs[1].questions[2].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[1].questions[4].title">
                <b-form-radio-group :id="blocs[1].questions[4].comment" v-model="blocs[1].questions[4].value" :options="blocs[1].questions[4].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="blocs[1].questions[4].value==blocs[1].questions[4].sub_questions[0].selected_parent_value" id="inclinaisonToit-grp" 
              :label="blocs[1].questions[4].sub_questions[0].title" :label-for="blocs[1].questions[4].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[4].sub_questions[0].comment" :type="blocs[1].questions[4].sub_questions[0].value_type" v-model="blocs[1].questions[4].sub_questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="exposition-grp" :label="blocs[1].questions[3].title" :label-for="blocs[1].questions[3].comment">
                <b-form-input :id="blocs[1].questions[3].comment" type="text" v-model="blocs[1].questions[3].value" ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="typeTuile-grp" :label="blocs[1].questions[5].title" :label-for="blocs[1].questions[5].comment">
                <b-form-select :id="blocs[1].questions[5].comment" v-model="blocs[1].questions[5].value" :options="blocs[1].questions[5].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-if="blocs[1].questions[5].value==blocs[1].questions[5].sub_questions[0].selected_parent_value" id="autreTypeTuile-grp" 
                :label="blocs[1].questions[5].sub_questions[0].title" :label-for="blocs[1].questions[5].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[5].sub_questions[0].comment" :type="blocs[1].questions[5].sub_questions[0].value_type" v-model="blocs[1].questions[5].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="autreTypeTuile-grp" 
                :label="blocs[1].questions[6].title" :label-for="blocs[1].questions[6].comment">
                <b-form-input :id="blocs[1].questions[6].comment" :type="blocs[1].questions[6].value_type" v-model="blocs[1].questions[6].value"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
               <b-form-group :label="blocs[1].questions[7].title">
                <b-form-radio-group :id="blocs[1].questions[7].comment" v-model="blocs[1].questions[7].value" :options="blocs[1].questions[7].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
                <h3>{{blocs[1].questions[10].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4">
              <b-form-group id="nbPanneaux-grp" :label="blocs[1].questions[8].title" :label-for="blocs[1].questions[8].comment">
                <b-form-input :id="blocs[1].questions[8].comment" :type="blocs[1].questions[8].value_type" v-model="blocs[1].questions[8].value" number></b-form-input>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="qtep-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="puissancePanneaux-grp" :label="blocs[1].questions[9].title" :label-for="blocs[1].questions[9].comment">
                <b-form-select :id="blocs[1].questions[9].comment" v-model="blocs[1].questions[9].value" :options="blocs[1].questions[9].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="pw-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="total-puissancePanneaux-grp" label="Puissance totale" label-for="total-puissancePanneaux">
                <b-form-input id="total-puissancePanneaux" type="text" :value="blocs[1].questions[8].value * blocs[1].questions[9].value" readonly number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
                    <b-form-row>
            <b-col md="4">
              <b-form-group id="batterie-grp" :label="blocs[1].questions[11].title" :label-for="blocs[1].questions[11].comment">
                <b-form-select :id="blocs[1].questions[11].comment" :type="blocs[1].questions[11].value_type" v-model="blocs[1].questions[11].value" :options="blocs[1].questions[11].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="qtep-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Chauffage -->   
        <b-tab :title="blocs[2].title" :title-link-class="linkClass(blocs[2].id)" @click="selectedTab=blocs[2].id"  v-if="mode !='pv'">
          <b-form-row v-if="mode =='vt'">
            <b-col md="3">
              <b-form-group :label="blocs[0].questions[4].title">
                <b-form-radio-group id="radio-group-2" v-model="blocs[0].questions[4].value" name="radio-sub-component" :options="blocs[0].questions[4].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="blocs[0].questions[4].value==blocs[0].questions[4].sub_questions[0].selected_parent_value" id="numEtage-grp" :label="blocs[0].questions[4].sub_questions[0].title" :label-for="blocs[0].questions[4].sub_questions[0].comment">
                <b-form-input :id="blocs[0].questions[4].sub_questions[0].comment" :type="blocs[0].questions[4].sub_questions[0].value_type" v-model="blocs[0].questions[4].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='vt'">       
            <b-col md="6">
              <b-form-group id="comblesAmenagees-grp" :label="blocs[0].questions[5].title" :label-for="blocs[0].questions[5].comment">
                <b-form-select :id="blocs[0].questions[5].comment" v-model="blocs[0].questions[5].value" :options="blocs[0].questions[5].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
              <h3>{{blocs[2].questions[11].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="centrale-grp" :label="blocs[2].questions[1].title">
                <b-form-select :id="blocs[2].questions[1].comment" v-model="blocs[2].questions[1].value" :options="blocs[2].questions[1].list_values">
                </b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="3">
              <b-form-group id="energie-grp" v-if="blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[0].selected_parent_value" :label="blocs[2].questions[1].sub_questions[0].title" :label-for="blocs[2].questions[1].sub_questions[0].comment">
                <b-form-select :id="blocs[2].questions[1].sub_questions[0].comment" v-model="blocs[2].questions[1].sub_questions[0].value" :options="blocs[2].questions[1].sub_questions[0].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-nrj" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>            
            <b-col md="3">
              <b-form-group id="anneeChaudiere-grp" v-if="blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[2].selected_parent_value" :label="blocs[2].questions[1].sub_questions[2].title" :label-for="blocs[2].questions[1].sub_questions[2].comment">
                <b-form-select :id="blocs[2].questions[1].comment" v-model="blocs[2].questions[1].sub_questions[2].value" :options="blocs[2].questions[1].sub_questions[2].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group id="typeChaudiere-grp" 
                v-if="blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[1].selected_parent_value && blocs[2].questions[0].sub_questions[0].value != 'Electricité (PAC Air/Eau)'" 
                :label="blocs[2].questions[1].sub_questions[1].title" 
                :label-for="blocs[2].questions[1].sub_questions[1].comment">
                <b-form-select :id="blocs[2].questions[1].comment" v-model="blocs[2].questions[1].sub_questions[1].value" :options="blocs[2].questions[1].sub_questions[1].list_values"></b-form-select>
              </b-form-group>
            </b-col> -->
          </b-form-row>
          <b-form-row>
            <b-col md="6">
                <b-form-group id="cuve-grp" v-if="blocs[0].questions[0].value=='Entre 2 et 15 ans' && blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[3].selected_parent_value && blocs[2].questions[1].sub_questions[0].value == 'Fioul'" :label="blocs[2].questions[1].sub_questions[3].title" :label-for="blocs[2].questions[1].sub_questions[3].comment">
                <b-form-select :id="blocs[2].questions[1].sub_questions[3].comment" v-model="blocs[2].questions[1].sub_questions[3].value" :options="blocs[2].questions[1].sub_questions[3].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-cuve" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- <b-form-row>
            <b-col md="4">
              <b-form-group id="generateur-grp" :label="blocs[2].questions[2].title" :label-for="blocs[2].questions[2].comment">
                <b-form-select :id="blocs[2].questions[2].comment" v-model="blocs[2].questions[2].value" :options="blocs[2].questions[2].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group  id="precisezGenerateur-grp" v-if="blocs[2].questions[2].value==blocs[2].questions[2].sub_questions[0].selected_parent_value" :label="blocs[2].questions[2].sub_questions[0].title" :label-for="blocs[2].questions[2].sub_questions[0].comment">
                <b-form-input :id="blocs[2].questions[2].sub_questions[0].comment" :type="blocs[2].questions[2].sub_questions[0].value_type" v-model="blocs[2].questions[2].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="anneeGenerateur-grp" :label="blocs[2].questions[3].title" :label-for="blocs[2].questions[3].comment">
                <b-form-select :id="blocs[2].questions[3].comment" v-model="blocs[2].questions[3].value" :options="blocs[2].questions[3].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row> -->
          <b-form-row>
            <b-col md="6">
              <b-form-group v-if="(blocs[2].questions[1].value =='Chauffage central' 
                || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air') && 
                  blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[1].selected_parent_value && blocs[2].questions[0].sub_questions[0].value != 'Electricité (PAC Air/Eau)'" id="chaudiereCondensation-grp" :label="blocs[2].questions[7].title" :label-for="blocs[2].questions[7].comment">
                <b-form-select :id="blocs[2].questions[7].comment" v-model="blocs[2].questions[7].value" :options="blocs[2].questions[7].list_values"></b-form-select>
              <b-form-text id="cha-help" v-if="modelVT.produits.includes('RO')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if=" blocs[2].questions[1].value =='Chauffage central' || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air'">
            <b-col md="6">
              <b-form-group id="plancherChauffant-grp" :label="blocs[2].questions[10].title" :label-for="blocs[2].questions[10].comment">
                <b-form-select :id="blocs[2].questions[10].comment" v-model="blocs[2].questions[10].value" :options="blocs[2].questions[10].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="plancherChauffant-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- v-if="chauffage=='combustible' && typeChauffageCombustible != 'insert'" -->
          <b-form-row>
            <b-col md="6">
              <b-form-group v-if=" blocs[2].questions[1].value !=null || blocs[2].questions[1].value =='Convecteurs Electriques' || blocs[2].questions[1].value == 'Radiateurs Electriques'" id="numRadiateur-grp" :label="blocs[2].questions[9].title" :label-for="blocs[2].questions[9].comment">
                <b-form-input :id="blocs[2].questions[9].comment" :type="blocs[2].questions[9].value_type" v-model="blocs[2].questions[9].value" trim number></b-form-input>
                <b-form-text id="numRad-help" v-if="modelVT.plancherChauffant == 'Oui'" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <!-- <b-col md="6">
              <b-form-group id="anneeChaudiere-grp" label="Quel est l’année de votre chaudière ?" label-for="anneeChaudiere">
                <b-form-input id="anneeChaudiere" type="number" v-model="anneeChaudiere" number></b-form-input>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group v-if=" blocs[2].questions[1].value =='Chauffage central' || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air'" id="matiereRadiateur-grp" :label="blocs[2].questions[8].title" :label-for="blocs[2].questions[8].comment">
                <b-form-select :id="blocs[2].questions[8].comment" v-model="blocs[2].questions[8].value" :options="blocs[2].questions[8].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>          
          <b-form-row>
            <b-col md="6">
              <b-form-group id="thermostat-grp" :label="blocs[2].questions[4].title">
                <b-form-select :id="blocs[2].questions[4].comment" v-model="blocs[2].questions[4].value" :options="blocs[2].questions[4].list_values">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
                <h3>{{blocs[2].questions[12].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="appoint-grp" :label="blocs[2].questions[5].title" :label-for="blocs[2].questions[5].comment">
                <b-form-checkbox-group :id="blocs[2].questions[5].comment" v-model="blocs[2].questions[5].value" :options="blocs[2].questions[5].list_values"></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="anneeAppoint-grp" :label="blocs[2].questions[6].title" :label-for="blocs[2].questions[6].comment">
                <b-form-select :id="blocs[2].questions[6].comment" v-model="blocs[2].questions[6].value" :options="blocs[2].questions[6].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="typeChauffageElectrique-grp" v-if="blocs[2].questions[0].value==blocs[2].questions[0].sub_questions[0].selected_parent_value" :label="blocs[2].questions[0].sub_questions[0].title" :label-for="blocs[2].questions[0].sub_questions[0].comment">
                <b-form-select :id="blocs[2].questions[0].sub_questions[0].comment" v-model="blocs[2].questions[0].sub_questions[0].value" :options="blocs[2].questions[0].sub_questions[0].list_values"></b-form-select>
              </b-form-group>

               <b-form-group id="typeChauffageCombustible-grp" v-if="blocs[2].questions[0].value==blocs[2].questions[0].sub_questions[1].selected_parent_value" :label="blocs[2].questions[0].sub_questions[1].title" :label-for="blocs[2].questions[0].sub_questions[1].comment">
                <b-form-select :id="blocs[2].questions[0].sub_questions[1].comment" v-model="blocs[2].questions[0].sub_questions[1].value" :options="blocs[2].questions[0].sub_questions[1].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="chauffage=='combustible' && typeChauffageCombustible != 'insert' && mode=='vt'" >
            <b-col md="6">
              <b-form-group id="lieuChaudiere-grp" label="Où est située votre chaudière actuelle ?" label-for="lieuChaudiere">
                <b-form-input id="lieuChaudiere" type="text" v-model="lieuChaudiere"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Votre chaudière est :">
                <b-form-radio-group id="radio-group-3" v-model="dispositionChaudiere" name="radio-sub-component3">
                  <b-form-radio value="sol" selected>Posée au sol</b-form-radio>
                  <b-form-radio value="mur">Fixée au mur</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row> 
            <!-- <b-form-row>
              <b-col md="3">
                <b-form-group id="garage-grp" label="Y'a-t-il un garage ?" label-for="garage">
                  <b-form-select id="garage" v-model="garage" :options="choixBinaireList"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group v-if="garage=='Oui'" id="garageIsole-grp" label="Est-il isolé ?" label-for="garageIsole">
                  <b-form-select id="garageIsole" v-model="garageIsole" :options="choixBinaireList"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row> -->
          <b-col md="3">
            <!-- <label for="">Combien de pièces comptez vous chauffer ?</label> -->
              <!-- <b-form-group id="nbPiecesChauffees-grp" label="Combien de pièces comptez vous chauffer ?" label-for="nbPiecesChauffees">
                <b-form-input id="nbPiecesChauffees" type="number" v-model="nbPiecesChauffees" number></b-form-input>
              </b-form-group>               -->
            </b-col>          
        </b-tab>
        <!-- ECS -->
        <b-tab :title="blocs[3].title" :title-link-class="linkClass(blocs[3].id)" @click="selectedTab=blocs[3].id" v-if="mode !='pv' && mode != 'pacRR'">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="nbAdultes-grp" :label="blocs[3].questions[1].title" :label-for="blocs[3].questions[1].comment">
                <b-form-input :id="blocs[3].questions[1].comment" :type="blocs[3].questions[1].value_type" v-model="blocs[3].questions[1].value" number></b-form-input>
              <b-form-text id="nba-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>            
            <b-col md="6">
              <b-form-group id="nbEnfants-grp" :label="blocs[3].questions[2].title" :label-for="blocs[3].questions[2].comment">
                <b-form-input :id="blocs[3].questions[2].comment" :type="blocs[3].questions[2].value_type" v-model="blocs[3].questions[2].value" number></b-form-input>
              <b-form-text id="nbe-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[3].questions[0].title">
                <b-form-select :id="blocs[3].questions[0].comment" v-model="blocs[3].questions[0].value" :options="blocs[3].questions[0].list_values" ></b-form-select>
              <b-form-text id="rec-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="recommandation-grp" v-if="blocs[3].questions[0].sub_questions[1].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[1].title" :label-for="blocs[3].questions[0].sub_questions[1].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[1].comment" v-model="blocs[3].questions[0].sub_questions[1].value" :options="blocs[3].questions[0].sub_questions[1].list_values" stacked></b-form-radio-group>
              </b-form-group>
              <b-form-group id="recommandation2-grp" v-if="blocs[3].questions[0].sub_questions[2].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[2].title" :label-for="blocs[3].questions[0].sub_questions[2].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[2].comment" v-model="blocs[3].questions[0].sub_questions[2].value" :options="blocs[3].questions[0].sub_questions[2].list_values" stacked></b-form-radio-group>
              </b-form-group>
              
            </b-col>
            <b-col md="6">
              <b-form-group id="instan-grp" v-if="blocs[3].questions[0].value==blocs[3].questions[0].sub_questions[0].selected_parent_value" :label="blocs[3].questions[0].sub_questions[0].title" :label-for="blocs[3].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[3].questions[0].comment" :type="blocs[3].questions[0].sub_questions[0].value_type" v-model="blocs[3].questions[0].sub_questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>

            <!-- <b-col md="6">
              <b-form-group v-if="productionEauChaude=='chaudiere'" id="cumulus-grp" label="Avez-vous également un cumulus électrique ?" label-for="cumulus">
                <b-form-select id="cumulus" v-model="cumulus" :options="choixBinaireList"></b-form-select>
              </b-form-group>
            </b-col> -->
          </b-form-row> 
          <b-form-row>
            <b-col md="6">
              <b-form-group id="anneeECS-grp" :label="blocs[3].questions[3].title" :label-for="blocs[3].questions[3].comment">
                <b-form-select :id="blocs[3].questions[3].comment" v-model="blocs[3].questions[3].value" :options="blocs[3].questions[3].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="hauteurPlafond-grp" :label="blocs[3].questions[4].title" :label-for="blocs[3].questions[4].comment">
                <b-input-group append="cm">
                  <b-form-input :id="blocs[3].questions[4].comment" :type="blocs[3].questions[4].value_type" v-model="blocs[3].questions[4].value" number></b-form-input>
                </b-input-group>
                <b-form-text id="hp-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>  
          </b-form-row>         
        </b-tab>
        <!-- Ventialtion -->
        <b-tab :title="blocs[4].title" :title-link-class="modelVT.produits.includes('VMC') ? linkClass(blocs[4].id) : ['bg-light', 'text-dark']" @click="selectedTab=blocs[4].id" :disabled="!modelVT.produits.includes('VMC')">

          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[4].questions[1].title">
                <b-form-select  id="radio-group-66" v-model="blocs[4].questions[1].value" name="radio-sub-component66" :options="blocs[4].questions[1].list_values">
                </b-form-select >
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-if="blocs[4].questions[1].value == blocs[4].questions[1].sub_questions[0].selected_parent_value"
              :label="blocs[4].questions[1].sub_questions[0].title" :label-for="blocs[4].questions[1].sub_questions[0].comment">
                <b-form-select  id="radio-group-67" v-model="blocs[4].questions[1].sub_questions[0].value" name="radio-sub-component67" :options="blocs[4].questions[1].sub_questions[0].list_values">
                </b-form-select >
              </b-form-group>
            </b-col>
          </b-form-row>          
  
        </b-tab>
        <!-- Mon isolation -->
        <b-tab :title="blocs[8].title" :title-link-class="linkClass(blocs[8].id)" @click="selectedTab=blocs[8].id" v-if="mode =='vt'">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="qualiteIsolation-grp" :label="blocs[8].questions[0].title" :label-for="blocs[8].questions[0].comment">
                <b-form-select :id="blocs[8].questions[0].comment" v-model="blocs[8].questions[0].value" :options="blocs[8].questions[0].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="isolationExterieure-grp" :label="blocs[8].questions[1].title" :label-for="blocs[8].questions[1].comment">
                <b-form-select :id="blocs[8].questions[1].comment" v-model="blocs[8].questions[1].value" :options="blocs[8].questions[1].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="isolationExterieure-grp" :label="blocs[8].questions[2].title" :label-for="blocs[8].questions[2].comment">
                <b-form-select :id="blocs[8].questions[2].comment" v-model="blocs[8].questions[2].value" :options="blocs[8].questions[2].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Mon raccordement électrique -->
        <b-tab :title="blocs[9].title" :title-link-class="linkClass(blocs[9].id)" @click="selectedTab=blocs[9].id">
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[9].questions[0].title">
                <b-form-radio-group :id="blocs[9].questions[0].comment" v-model="blocs[9].questions[0].value" :options="blocs[9].questions[0].list_values">
                </b-form-radio-group>
                <b-form-text id="ele-help" v-if="modelVT.produits.includes('RO')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="puissanceCompteur-grp" :label="blocs[9].questions[1].title" :label-for="blocs[9].questions[1].comment">
                <b-form-select :id="blocs[9].questions[1].comment" v-model="blocs[9].questions[1].value" :options="blocs[9].questions[1].list_values"></b-form-select>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group id="autrePuissanceCompteur-grp" v-if="blocs[9].questions[1].value == blocs[9].questions[1].sub_questions[0].selected_parent_value"
              :label="blocs[9].questions[1].sub_questions[0].title" :label-for="blocs[9].questions[1].sub_questions[0].comment">
                <b-form-input :id="blocs[9].questions[1].sub_questions[0].comment" :type="blocs[9].questions[1].sub_questions[0].value_type" 
                v-model="blocs[9].questions[1].sub_questions[0].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Recommandations -->
        <b-tab :title="blocs[6].title" :title-link-class="linkClass(blocs[6].id)" @click="selectedTab=blocs[6].id" v-if="mode =='10'">
          <b-form-row>
            <b-col md="8">
              <b-form-group id="typeInstallation-grp" :label="blocs[6].questions[0].title" :label-for="blocs[6].questions[0].comment">
                <b-form-checkbox-group :id="blocs[6].questions[0].comment" v-model="blocs[6].questions[0].value" :options="blocs[6].questions[0].list_values" stacked></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
          <b-col md="4">
              <b-form-group id="capaciteBallon-grp" v-if="blocs[6].questions[0].value.includes(blocs[6].questions[0].sub_questions[0].selected_parent_value)"
              :label="blocs[6].questions[0].sub_questions[0].title" :label-for="blocs[6].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[6].questions[0].sub_questions[0].comment" :type="blocs[6].questions[0].sub_questions[0].value_type" v-model="blocs[6].questions[0].sub_questions[0].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="commentaire-grp" :label="blocs[6].questions[1].title" :label-for="blocs[6].questions[1].comment">
                <b-form-input :id="blocs[6].questions[1].comment" :type="blocs[6].questions[1].value_type" v-model="blocs[6].questions[1].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="Mon installation" :title-link-class="linkClass('installation')" @click="selectedTab='installation'" v-if="mode !='10' && !editMode">
          <Installation :informations="informations"/>
        </b-tab>
        <!-- Dépenses -->
        <b-tab :title="blocs[5].title" :title-link-class="linkClass(blocs[5].id)" @click="selectedTab=blocs[5].id">
         <b-form-row>       
            <b-col md="12">
              <h4>{{blocs[5].questions[12].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="depensesAnnuelleElectricite2-grp" :label="blocs[5].questions[1].title" :label-for="blocs[5].questions[1].comment">
                <b-form-input :id="blocs[5].questions[1].comment" :type="blocs[5].questions[1].value_type" v-model="blocs[5].questions[1].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleElectricite-grp" :label="blocs[5].questions[0].title" :label-for="blocs[5].questions[0].comment">
                <b-form-input :id="blocs[5].questions[0].comment" :type="blocs[5].questions[0].value_type" v-model="blocs[5].questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
            
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="depensesAnnuelleCombustible-grp" :label="blocs[5].questions[13].title" :label-for="blocs[5].questions[13].comment">
                <b-form-input :id="blocs[5].questions[13].comment" :type="blocs[5].questions[13].value_type" v-model="blocs[5].questions[13].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleCombustible2-grp" :label="blocs[5].questions[14].title" :label-for="blocs[5].questions[14].comment">
                <b-form-input :id="blocs[5].questions[14].comment" :type="blocs[5].questions[14].value_type" v-model="blocs[5].questions[14].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6" >
              <b-form-group id="depensesAnnuelleGaz-grp" :label="blocs[5].questions[2].title" :label-for="blocs[5].questions[2].comment">
                <b-form-input :id="blocs[5].questions[2].comment" :type="blocs[5].questions[2].value_type" v-model="blocs[5].questions[2].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleGazTCC-grp" :label="blocs[5].questions[3].title" :label-for="blocs[5].questions[3].comment">
                <b-form-input :id="blocs[5].questions[3].comment" :type="blocs[5].questions[3].value_type" v-model="blocs[5].questions[3].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuelleFioul-grp" :label="blocs[5].questions[4].title" :label-for="blocs[5].questions[4].comment">
                <b-form-input :id="blocs[5].questions[4].comment" :type="blocs[5].questions[4].value_type" v-model="blocs[5].questions[4].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleFioulTCC-grp" :label="blocs[5].questions[5].title" :label-for="blocs[5].questions[5].comment">
                <b-form-input :id="blocs[5].questions[5].comment" :type="blocs[5].questions[5].value_type" v-model="blocs[5].questions[5].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBois-grp" :label="blocs[5].questions[6].title" :label-for="blocs[5].questions[6].comment">
                <b-form-input :id="blocs[5].questions[6].comment" :type="blocs[5].questions[6].value_type" v-model="blocs[5].questions[6].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBoisTCC-grp" :label="blocs[5].questions[7].title" :label-for="blocs[5].questions[7].comment">
                <b-form-input :id="blocs[5].questions[7].comment" :type="blocs[5].questions[7].value_type" v-model="blocs[5].questions[7].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6" >
              <b-form-group id="depensesAnnuelleBuche-grp" :label="blocs[5].questions[8].title" :label-for="blocs[5].questions[8].comment">
                <b-form-input :id="blocs[5].questions[8].comment" :type="blocs[5].questions[8].value_type" v-model="blocs[5].questions[8].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBucheTCC-grp" :label="blocs[5].questions[9].title" :label-for="blocs[5].questions[9].comment">
                <b-form-input :id="blocs[5].questions[9].comment" :type="blocs[5].questions[9].value_type" v-model="blocs[0].questions[9].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuellePropane-grp" :label="blocs[5].questions[10].title" :label-for="blocs[5].questions[10].comment">
                <b-form-input :id="blocs[5].questions[10].comment" :type="blocs[5].questions[10].value_type" v-model="blocs[5].questions[10].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuellePropaneTCC-grp" :label="blocs[5].questions[11].title" :label-for="blocs[5].questions[11].comment">
                <b-form-input :id="blocs[5].questions[11].comment" :type="blocs[5].questions[11].value_type" v-model="blocs[5].questions[11].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Financement -->
        <b-tab title="Financement" :title-link-class="linkClass('financement')" @click="selectedTab='financement'" v-if="mode !='10' && !editMode">
          <Financement/>
        </b-tab>
        <!-- Checklist -->
        <b-tab :title="blocs[7].title" :title-link-class="linkClass(blocs[7].id)" @click="selectedTab=blocs[7].id">
          <b-form-row>
            <b-col md="10">
              <div v-for="question in blocs[7].questions" :key="question.id">
                <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
                <SingleFileUpload v-if="question.question_type =='uploadDoc' && (question.condition == null || modelVT && modelVT.produits.includes(question.condition) )" :id="question.comment" :label="question.title" :blockId=7 :questionId=question.id ></SingleFileUpload>
              </div>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- <b-tab title="Contrat" :title-link-class="linkClass('contrat')" @click="selectedTab='contrat'" v-if="mode =='10' || mode == 'pacRR' || mode == 'pacRO'  || mode == '7' && !editMode">
          <b-form-row>
            <b-col md="12">
              <b-icon icon="file-earmark-text" font-scale="1" class="mr-2"></b-icon>
              <b-link href="https://form.jotform.com/202902468538056" target="blank">Ouvrir le contrat dans une nouvelle fenêtre</b-link>
            </b-col>
          </b-form-row>
        </b-tab> -->
        <b-tab title="Dossier" :title-link-class="linkClass('dossier')" @click="selectedTab='dossier'" v-if="(mode =='10' || mode == 'pacRR' || mode == 'pacRO'  || mode == '7' || assistantMode || creationMode) && !editMode">
          <b-form-row>
            <b-col md="12">
              <CreationDossier :informations="informations" :blocs="blocs" :itemRequired="true"/>
            </b-col>
          </b-form-row>
        </b-tab>
        </div>
      </b-tabs>
      <b-card-footer v-if="editMode"><b-button variant="primary" @click="updateVT()">Enregistrer</b-button></b-card-footer>
    </b-card>
    </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import TextInput from "../components/TextInput";
import Installation from "../components/Installation"
import SingleFileUpload from "../components/SingleFileUpload"
import CreationDossier from '../components/CreationDossier'
import Financement from '../components/Financement'
import DossierService from '../services/DossierService'
import AidesService from '../services/AideService'

//import Param from '../components/Param.vue';
//import BlocItem from '../components/BlocItem'

export default {
  name: "MyQuestions",
  components: {
    TextInput,
    Installation,
    SingleFileUpload,
    CreationDossier,
    Financement
    //Param
    //BlocItem
  },
  data: () => ({
      index: 1,
      tabIndex: 3,      
      selectedTab: "primary",
      existeConjoint: false,
      show: true,
      mode: null,
      editMode : false,
      assistantMode : false,
      creationMode : false,
      categorieMPR: '',
      categorieCEE: '',
      zoneGeo:'',
      categorieColor:'',
      produits: [],
      message :'',
      blocs : [],
      showTabs : false,
      oblige: null , //Premium Energy
      numPieces:null, //0,
      garage:null,
      garageIsole:null,
      chauffage:null,
      nbPiecesChauffees: null, //0
      typeChauffageElectrique: null,
      numRadiateur:null, //0,
      typeChauffageCombustible:null,
      anneeChaudiere:null, //0,
      chaudiereCondensation: null, 
      matiereRadiateur : null,
      plancherChauffant : null,
      chauffageComplet : null,
      superficieChauffee : null, //0,
      qualiteIsolation : null,
      typeVitrage : null,
      isolationExterieure: null,
      productionEauChaude:null,
      cumulus:null,
      installationElectrique:null,
      exposition:null,
      dispositionChaudiere:null,
      numPersonnesFoyer: null, //0
      lieuChaudiere:null,
      typologieClient:null,
      // productionElectricite: null,

      lieuPPV:null,
      puissanceCompteur: null, //0,
      autrePuissanceCompteur: null, //0
      distanceAnnexeMaison:null, //0,
      precisezAnnexe:null,
      productionElectricite: null,
      vtId: null,
      tempAides : [],
      obligeList : [],
      typeTuileList : [
        { value: null, text: 'Choisir ...' },
        { value: 'PVC', text: 'PVC' },
        { value: 'Taule', text: 'Taule'},
        { value: 'Autre', text: 'Autre'}
      ],
      modeList : [
        { value: null, text: 'Choisir ...' },
        // { value: 'pv', text: 'Photovoltaïque' },
        // { value: 'pacRR', text: 'PAC Air/Air' },
        // { value: 'pacRO', text: 'PAC Air/Eau' },
        { value: '7', text: 'VENTE' },
        // { value: 'mixte', text: 'Mixte'},
        { value: '10', text: 'AMO'}
      ],
		zoneCP : [
      {zone : 'A' , cp : ['2A','2B', '86', '06']}, 
			{zone : 'B' , cp : ['29','22','56','50', '44', '85','17', '33', '40']},  
			{zone : 'C' , cp : ['35','16', '24', '47', '32', '64', '65', '82', '81', '34', '11', '66', '09', '31', '13']},  
			{zone : 'D' , cp : ['76','60','02','14','27','95','75','77','92','93','94','91','78','61', '28','45','53','72','41','18','49','37','36','49','86', '26', '07', '46', '12']},  
			{zone : 'E' , cp : ['87','23', '19','03','63','15', '43', '07', '48', '04',]},  
			{zone : 'F' , cp : ['59','62','80']},  
			{zone : 'G' , cp : ['08','51','10','89','21','58','71','39','42','69','01','74', '38', '73', '05', ]}, 
			{zone : 'H' , cp : ['55','52','70', '25']}, 
			{zone : 'I' , cp : ['57','54','88','67','68', '90']}
		],d : {
			A : [[200,-2], [400,-4],[600,-6],[800,-8],[1000,-10],[1200,-12],[1400,-14],[1600,-16],[1800,-18],[2000,-20],[2200,1]], 
			B : [[200,-4], [400,-5],[600,-6],[800,-7],[1000,-8],[1200,-9],[1400,-10],[1600,1],[1800,1],[2000,1],[2200,1]],  
			C : [[200,-5], [400,-6],[600,-7],[800,-8],[1000,-9],[1200,-10],[1400,-11],[1600,-12],[1800,-13],[2000,-14],[2200,-15]],  
			D : [[200,-7], [400,-8],[600,-9],[800,-11],[1000,-13],[1200,-14],[1400,-15],[1600,1],[1800,1],[2000,1],[2200,1]],  
			E : [[200,-8], [400,-9],[600,-11],[800,-13],[1000,-15],[1200,-17],[1400,-19],[1600,-21],[1800,-23],[2000,-25],[2200,27]],  
			F : [[200,-9], [400,-10],[600,-11],[800,-12],[1000,-13],[1200,1],[1400,1],[1600,1],[1800,1],[2000,1],[2200,1]],  
			G : [[200,-10], [400,-11],[600,-13],[800,-14],[1000,-17],[1200,-19],[1400,-21],[1600,-23],[1800,-24],[2000,-25],[2200,29]], 
			H : [[200,-12], [400,-13],[600,-15],[800,-17],[1000,-19],[1200,-21],[1400,-23],[1600,-24],[1800,1],[2000,1],[2200,1]], 
			I : [[200,-15], [400,-15],[600,-19],[800,-21],[1000,-23],[1200,-24],[1400,-25],[1600,1],[1800,1],[2000,1],[2200,1]],
		}
  }),
  mounted(){

    this.$store.dispatch('getPacks', this.idOrganisation).then(() => {

    })

    this.blocs = this.modelVT.blocs
    this.mode = 7
    DossierService.GetTypes().then(result => {
      if(result.data.statut == 'OK') {
        this.modeList = result.data.liste.map(f=> {return {value: f.id_type_dossier, text: f.libelle_type_dossier_app}})
        this.modeList.unshift({ value: null, text: 'Choisir ...' })
        
      }
      else {
        this.message = result.error_message
      }
    }).catch(err => {
        console.log(err.message)
        this.message = err.message
      }
    )

    DossierService.GetObliges().then(result => {
      if(result.data.statut == 'OK') {
        this.obligeList = result.data.liste.map(f=> {return {value: f, text: `${f.raison_sociale} (${f.libelle_activite_organisation}) `}})
        this.obligeList.unshift({ value: null, text: 'Choisir ...' })
        this.oblige = result.data.liste.find(x => x.id_organisation == '11523')
      }
      else {
        this.message = result.error_message
      }
    }).catch(err => {
        console.log(err.message)
        this.message = err.message
      }
    )

    if(this.$route.params && this.$route.params.vtId && this.$route.params.id_regie == null) {
      this.editMode = true;
      this.showTabs = true
      this.assistantMode = false;
      this.creationMode = false;
      this.vtId = this.$route.params.vtId
      DossierService.GetVT(this.$store.state.auth.user.id_user_membre, this.$route.params.vtId).then(response => {
        console.log(response.data.dataVt.json_vt_string)
        const vt = JSON.parse(response.data.dataVt.json_vt_string)
        if(vt.blocs)
          this.blocs = this.modelVT.parse(vt.blocs)
        else 
          this.blocs = this.modelVT.parse(vt)
                 
        }).catch(err => {
          console.log(err)
          this.message = err.message
        }
      )
    }

    if(this.$route.params && this.$route.params.id_regie && this.$route.params.id_user_membre) {
      this.editMode = false
      this.assistantMode = false
      this.creationMode = true;
      this.tabIndex= 0
      this.selectedTab = 'primary'
      this.informations.idRegie = this.$route.params.id_regie
    }

    if(this.$route.params && this.$route.params.id_regie && this.$route.params.id_client && this.$route.params.id_dossier) {
      this.editMode = false
      this.assistantMode = true
      this.creationMode = false
      this.informations.id = this.$route.params.id_client

      this.tabIndex= 0
      this.selectedTab = 'primary'
    }

    this.$store.dispatch("setClient", this.informations)
    this.$store.dispatch('GetProgrammeAide', this.mode || 7)
  },
  computed : {
    informations() {
      return this.$store.getters.client
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    },
    codePostal() {
      return this.$store.getters.client.codePostal
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    aides() {
      return this.$store.getters.aides
    },
    id_user_membre() {
      return this.$store.getters.user.id_user_membre
    },
    idOrganisation() {
      return this.$store.getters.idOrganisation
    },
    fabricantsDefaut() {
      return this.$store.getters.fabricantsDefaut
    },
    g() {
     return  Number(this.modelVT.blocs[0].questions[44].value)
    },
    volume() {
      return this.modelVT.blocs[0].questions[45].value
    },
    altitude() {
      return this.modelVT.blocs[0].questions[46].value
    }
  },
  watch: {
    informations: {
      handler(){
        if(this.informations.codePostal != null && this.totalPersonnes !=null && this.totalRevenu !=null) {
          this.showTabs = true
        }
        this.$refs.observer.validate().then(success => {
          //this.showTabs = success

          if(success) {
            this.$store.dispatch("setClient", this.informations)
          }
        })
      },
      deep: true
    },
    codePostal : {
      handler(val) {
        if(val.length >= 5) {
          this.categorieZoneGeo()
          this.calculerP()
        }
      }
    },
    altitude : {
      handler() {
        this.calculerP()
      }
    },
    g : {
      handler() {
        this.calculerP()
      }
    },
    volume : {
      handler() {
        this.calculerP()
      }
    },
    totalRevenu : {
      handler() {
          this.categorieZoneGeo()
      }
    },
    totalPersonnes : {
      handler() {
          this.categorieZoneGeo()
      }
    },
    modelVT: {
      handler(){
        this.$store.dispatch("setModelVT", this.modelVT)
        if(this.modelVT.produits.includes('PV')) {
          if(this.informations.typeDossier != 1) {
            this.informations.typeDossier = 1
            
          }
        }
        else {
          if(this.informations.typeDossier != this.mode) {
            this.informations.typeDossier = this.mode
          }
          this.modelVT.blocs[1].questions[9].value = null
        }
        //console.log(this.modelVT.materiel)
      },
      deep: true
    },
    oblige : {
      handler() {
        this.$store.dispatch("MajOblige", this.oblige)
      }
    }
  },
  methods: {
    linkClass(tab) {
        if (this.selectedTab === tab) {
          return ['bg-info', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },
    onMode() {
      switch (this.mode) {
        case 'pacRR':
            this.chauffage = "electrique"
          break;
        case 'pacRO':
          this.chauffage = "combustible"
          break;
        case '7' :
          this.chauffage = 'combustible'
          this.showTabs = false
          break;
        case '10' :
          this.showTabs = true
          break;
        default:
          this.chauffage = null
            break;
      }

      this.tabIndex= 0
      this.selectedTab = "primary"
      this.informations.typeDossier = this.mode
      this.$store.dispatch("setClient", this.informations)
      this.$store.dispatch('GetProgrammeAide', this.mode)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showParam() {
      this.$bvModal.show('modal')
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT() {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.informations.nom = ''
      this.informations.nom_conjoint = ''
      this.informations.prenom = ''
      this.informations.prenom_conjoint = ''
      this.informations.adresse = ''
      this.informations.codePostal = null
      this.informations.ville = ''
      this.informations.mobile = null
      this.informations.telephone = null
      this.informations.email = ''
      this.informations.revenu1 = null
      this.informations.revenu2 = null
      this.informations.revenu3 = null
      this.informations.nbPersonnes1 = null
      this.informations.nbPersonnes2 = null
      this.informations.nbPersonnes3 = null

      this.tabIndex= 0
      this.selectedTab = "primary"

      this.blocs[10].questions[0].value = ['RO','EC']
      this.$store.dispatch("viderDocuments")
    },
    updateVT() {
      if(this.vtId) {
        DossierService.UpdateVT({
          code_app : "APP_COMMERCIALE",
          numTransaction : Math.floor(Math.random() * 100000) + 1,
          id_user_membre : this.id_user_membre,
          module: "DOSSIER_AFFAIRE",
          id_VT : this.vtId,
          batiment : "",
          detail_emplacement : "",
          jsonVtString : JSON.stringify(this.blocs)
        }).then(() => {
            this.$bvModal.msgBoxOk('Enregistrement réussi', {
                  title: 'Confirmation',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'primary',
                  hideHeader : true,
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                })
        })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    updateFabricantDefaut() {
      this.$store.dispatch("updateFabricantDefaut", this.fabricantsDefaut)
    },
    handleConjoint() {
      this.existeConjoint =! this.existeConjoint
      this.informations.nom_conjoint = ''
      this.informations.prenom_conjoint = ''
    },
    calculerP() {

      const z =  this.zoneCP.find(x=> x.cp.includes(this.informations.codePostal.substring(0,2))) //'A'

      if(z == undefined)
        return 0

      let  deltaT = 0

      if(z.zone != null && this.altitude != null) {
				deltaT = this.d[z.zone].find(x=> { return x[0] >= this.altitude})[1]
			}

			if(this.g != 0 && this.volume != 0) {
        this.blocs[0].questions[47].value = this.g * this.volume * deltaT
        return this.blocs[0].questions[47].value;
      }
			else 
				return 0
    },
    categorieZoneGeo() {
      var data = {
          code_app : "APP_COMMERCIALE",
          numTransaction : Math.floor(Math.random() * 100000) + 1,
          id_user_membre : this.id_user_membre,
          id_programme_aide : 1,
          revenu_fiscal : this.totalRevenu,
          nombre_personne : this.totalPersonnes,
          cp : this.informations.codePostal ? this.informations.codePostal.substring(0,2) : "0",
          fk_organisation : "3651"
        }
        
        AidesService.GetCategorieClient(data)
        .then(response => {
  
          if(response.data.statut == "OK") {
            this.informations.categorieMPR = response.data.nom_categorieMPR
            this.informations.id_categorieMPR = response.data.id_categorieMPR
            this.informations.categorieCEE = response.data.nom_categorieCEE
            this.informations.id_categorieCEE = response.data.id_categorieCEE
            this.informations.zoneGeo = response.data.zone_geo

            switch (this.informations.categorieMPR) {
              case "Très modestes":
                this.categorieColor = "Bleue"
                break;
              case "Modestes":
                this.categorieColor = "Jaune"
                break;
              case "Intermédiaires":
                this.categorieColor = "Violet"
                break;
              case "Les Plus Elevés":
                this.categorieColor = "Rose"
                break;
                                    
              default:
                break;
            }
          }
          
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

legend {
  font-size: 16px;
}
/* Très modestes (Grands Précaires pour CEE)	
Modestes (Précaires pour CEE)	
Intermédiaires (Classiques pour CEE)	
Les Plus Elevés (Classiques pour CEE)	 */
.Bleue {
  background-color: cornflowerblue;
  color: #fff;
}
.Jaune {
  background-color:yellow;
}
.Violet {
  background-color:blueviolet ;
  color: #fff;
}
.Rose {
  background-color: violet;
  color: #fff;
}
</style>
