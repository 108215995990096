import Vue from 'vue'
import Vuex from 'vuex'
import Catalog from './modules/catalog'
import auth from './modules/auth'
import pieces from './modules/pieces'
import client from './modules/client'
import document from './modules/document'
import aides from './modules/aides'
import modelVT from './modules/modelVT'
import financement from './modules/financement'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Catalog,
    auth,
    pieces,
    client,
    document,
    aides,
    modelVT,
    financement
  }
})
