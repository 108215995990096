import Api from '@/services/Api'
//var axios = require('axios');
// import authHeader from './Auth-header';

class AuthService {
  login(user) {
    // localStorage.setItem('user', JSON.stringify(user));
    // return Promise.resolve(user)

    var FormData = require('form-data');
    var data = new FormData();
    data.append('login', user.username);
    data.append('pswd', user.password);
    data.append('code_app', 'APP_COMMERCIALE');
    
    return Api()
      .post('/user/login' , data)
      .then(response => {
        //if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        //}

        return Promise.resolve(response.data);
      });
  }

  logout(){
    localStorage.removeItem('user')
  }
}

export default new AuthService();