<template>
	<div>

		<b-input-group class="mb-2">
			<b-form-input aria-label="type" type="text" placeholder="Type de travaux" v-model="type"></b-form-input>
			<b-form-input aria-label="date" type="date" placeholder="Date des travaux" v-model="date"></b-form-input>
			<b-input-group-append>
				<b-button variant="primary"><b-icon icon="plus" @click="addObject"/></b-button>
			</b-input-group-append>
		</b-input-group>

		<b-input-group v-for="(object, index) in objectArray" :key="index" class="mb-2">
			<b-form-input aria-label="type" type="text" placeholder="Type de travaux" v-model="object.type"></b-form-input>
			<b-form-input aria-label="date" type="date" placeholder="Date des travaux" v-model="object.date"></b-form-input>
			<b-input-group-append>
				<b-button variant="outline-danger" @click="removeObject(index)"><b-icon icon="trash" /></b-button>
			</b-input-group-append>
		</b-input-group>

	</div>
</template>
  
<script>
export default {
	data() {
		return {
			objectArray: [],
			type: '',
			date: ''
		};
	},
	methods: {
		addObject() {
			if (this.type !== '' && this.date !== '') {
				this.objectArray.push({ type: this.type, date : this.date });
				this.type = '';
				this.date = ''
				this.$emit('updated', this.objectArray)
			}
		},
		removeObject(index) {
			this.objectArray.splice(index, 1);
			this.$emit('updated', this.objectArray)
		}
	}
};
</script>
  