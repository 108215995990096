import Api from '@/services/Api'
import store from '../store/index'


function UploadDocument(doc) {

  var FormData = require('form-data');
  var data = new FormData();

  data.append('code_app', doc.code_app);
  data.append('numTransaction', doc.numTransaction);
  data.append('id_user_membre', doc.id_user_membre);
  data.append('module', doc.module);
  data.append('id_fiche', doc.id_fiche);
  data.append('titre_doc', '');
  data.append('id_categorie_fichier', doc.id_categorie_fichier)
  data.append('description_doc', doc.description_doc)
  data.append('VT_Id', doc.VT_Id);
  data.append('VT_JSchedule_api', doc.VT_JSchedule_api);
  data.append('VT_blockId', doc.VT_blockId);
  data.append('VT_blocklIteration', doc.VT_blocklIteration);
  data.append('VT_comment', doc.VT_comment);
  data.append('VT_itemId', doc.VT_itemId);
  data.append('userfile', doc.file);
  data.append('bloc_portlet', doc.bloc_portlet);
  data.append('id_bloc_portlet', doc.id_bloc_portlet);

  return Api().post('/document/upload' , data, 
  {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function( progressEvent ) {
      store.dispatch('majProgression', { id:doc.id_bloc_portlet, uploadPercentage : parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))})
    }.bind(this)
  })
}

function SendDocument(data){
    return Api().post('/document/envoi' , data)
}

export default {
    UploadDocument, SendDocument 
}