import Api from '@/services/Api'
import authHeader from './Auth-header';

async function CreateFacturation (facturation) {
  return Api().post('facturation/create', facturation, { headers: authHeader()})
}

async function EditFacturation (facturation) {
  let headers = authHeader()
  //headers["Access-Control-Allow-Origin"]= "*"
  console.log(headers)
  return Api().post('facturation/edition', facturation, { headers: headers})
}

async function EnvoiFacturation (facturation) {
  return Api().post('facturation/envoi', facturation, { headers: authHeader()})
}

export default {
  CreateFacturation, EditFacturation, EnvoiFacturation
}