import CatalogService from '../../services/CatalogService'
import Produit from '../../models/produit'
import Article from '../../models/article'
//import AideService from '../../services/AideService'

//import Vue from 'vue'

const state =  {
  selectedPacks: [],
  packs :[],
  packList : [],
  fabricants : [],
  fabricantsDefaut : [],
  delta : 0,
  nextId: 1,
  totalTTC :0
};

const getters = {
  packs : state => { return state.packs},
  delta : state => { return state.delta},
  packList: state => { return state.packList},
  fabricants: state => state.fabricants,
  fabricantsDefaut: state => state.fabricantsDefaut,
  selectedPacks : state => {
    return state.selectedPacks
  },
  totalTTC : state => { return state.totalTTC}
}

const mutations = {
  GET_PACKS(state, packs) {
    state.packs = packs
    state.packList = []
    state.packs.forEach((pack) => {
      console.log(pack.famille)
      var index = state.packList.findIndex(x=> { return x.label == pack.famille})
      if(index == -1) {
        state.packList.push({ label: pack.famille, options:[{value:pack, text:`${pack.libelle}`}]})
      }
      else{
        state.packList[index].options.push({value:pack, text:`${pack.libelle}`})
      }
    })

    //sort
    state.packList.forEach(group => {
      group.options.sort((a,b) => 
        {
          if (a.text > b.text) return 1;
          if (b.text > a.text) return -1;
        
          return 0;
      })
    })
  },
  GET_MAKERS_DEFAULT(state, packs) {
    packs.forEach((pack) => {
      if(pack.type != "Fournitures" && pack.type != "Pose") {
        console.log(pack.type)
        var index = state.fabricantsDefaut.findIndex(x=> { return x.label == pack.type})
        let default_value = pack.fabricantId
        if(pack.type == 'Pompe à Chaleur Air/Eau')
          default_value = '8645'
  
        if(pack.type == 'Ballon Thermodynamique')
            default_value = '10210'
  
        if(pack.type == 'Ballon Solaire')
          default_value = '10218'
  
        if(index == -1) {
          state.fabricantsDefaut.push({ label: pack.type, value:default_value, options:[{value:pack.fabricantId, text:`${pack.fabricantLibelle}`}]})
        }
        else{
          var index2 = state.fabricantsDefaut[index].options.findIndex(x=> { return x.value == pack.fabricantId})
          if(index2 == -1) {
            state.fabricantsDefaut[index].value = default_value
            state.fabricantsDefaut[index].options.push({value:pack.fabricantId, text:`${pack.fabricantLibelle}`})
          }
        }
      }
    })

    //sort
    state.packList.forEach(group => {
      group.options.sort((a,b) => 
        {
          if (a.text > b.text) return 1;
          if (b.text > a.text) return -1;
        
          return 0;
      })
    })
  },
  UPDATE_MAKERS_DEFAULT(state, fabricantsDefaut) {
    state.fabricantsDefaut = fabricantsDefaut
  },
  GET_MAKERS(state, fabricants) {
    state.fabricants = fabricants.sort((a,b) => {
      if (a.text > b.text) return 1;
      if (b.text > a.text) return -1;
        
      return 0;
    })
  },
  ADD_PACK(state, obj) {
    let pack = obj.pack
    const index = state.selectedPacks.findIndex(p => p.id == pack.id);
    if(index == -1) {
      state.delta = 0
      state.selectedPacks = state.selectedPacks.concat(pack);
    }
  },
  DELETE_PACK(state, id) {
    state.selectedPacks = state.selectedPacks.filter(p => p.id != id);
  },
  DELETE_ALL_PACK(state) {
    state.selectedPacks = [];
  },
  UPDATE_PACK(state, pack) {
    var index = state.selectedPacks.findIndex(p => p.id == pack.id);
    if(index > 0) 
      state.selectedPacks[index] = pack
  },
  UPDATE_ARTICLE(state, article) {
    state.selectedPacks.forEach(pack => {
      pack.articles.forEach(item => {
        if(article.id == item.id) {
          item.prixHT = Math.round(item.prixTTC / (1 + item.tva) * 100) / 100
        }
      });
    });
  },
  UPDATE_TOTAL_TTC(state, totalTTC) {
    state.totalTTC = totalTTC
  }
}

const actions = {
  getPacks( context ) {
    return CatalogService.GetArticleAudit()
      .then(response => {

        var packs =  []
        var fabricants = []
        //var fabricantsParFamille = []
        response.data.list_articles.forEach(produit => {
          if(produit.libelle_type_fr == 'AMO' && produit.libelle_gamme_fr != 'Aucune') 
          {
            var p = new Produit(produit.id_article, produit.designation_ligne_fr,produit.fk_orga_fab, produit.fab_raison_sociale, produit.reference_interne, produit.reference_distributeur, produit.libelle_famille_fr, produit.libelle_type_fr, produit.fk_type, produit.libelle_gamme_fr, produit.fk_gamme, produit.list_articles_caracteristique)
            var prixHT =  produit.p_vente_dev1
            var modifiable = produit.p_vente_modifiable == "1" ? true : false
            
            var a = new Article(produit.id_article, produit.reference_interne, produit.designation_ligne_fr, produit.taux_tva, prixHT, modifiable , produit.fk_orga_fab, produit.fab_raison_sociale)
            p.articles.push(a)
            if(produit.list_articles_associe.length > 0) {
              produit.list_articles_associe.forEach(article => {
                prixHT = article.p_vente_dev1 
                modifiable = article.p_vente_modifiable == "1" ? true : false
                a = new Article(article.fk_article_associe, article.reference_interne, article.designation_ligne_fr, article.taux_tva, article.p_vente_dev1, modifiable , article.fk_orga_fab, article.fab_raison_sociale)
                p.articles.push(a)
              })
            }
            packs.push(p)

            // Fabricants
            if(fabricants.find(x=> x.value == produit.fk_orga_fab) == undefined) {    
              fabricants.push({value:produit.fk_orga_fab, text:`${produit.fab_raison_sociale}`})
            }
          }
        })

        context.commit('GET_PACKS', packs)
        context.commit('GET_MAKERS', fabricants)
        context.commit('GET_MAKERS_DEFAULT', packs)
      })
      .catch(error => {
        console.log(error)
      })
  }, 
  addPack(context, pack) {
    context.commit("ADD_PACK", {pack, client:context.rootState.client});
  },
  deletePack(context, id) {
    context.commit("DELETE_PACK", id);
  },
  deleteAllPack(context) {
    context.commit("DELETE_ALL_PACK");
  },
  updatePack(context, pack) {
    context.commit("UPDATE_PACK", pack);
  },
  updateArticle(context, article) {
    context.commit("UPDATE_ARTICLE", article);
  },
  updateTotalTTC(context, totalTTC) {
    context.commit('UPDATE_TOTAL_TTC', totalTTC)
  },
  updateFabricantDefaut(context, fabricantsDefaut) {
    context.commit("UPDATE_MAKERS_DEFAULT", fabricantsDefaut);
  },
}

export default {
    state, getters, mutations, actions
}