<template>
  <b-container class="pt-2" fluid="md">
    <div class="d-flex flex-row mt-4  justify-content-start">
        <go-back/> <h3>Visite Technique</h3>
      </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <!-- Tabs with card integration -->
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset">
      <b-card no-body>
        <b-tabs v-model="tabIndex" small card pills>
          <b-tab title="Mes informations" :title-link-class="linkClass('info')" @click="selectedTab='info'">
          <b-form-row >
            <b-col md="6">
              <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom" ></TextInput>
            </b-col>
            <b-col md="6">
              <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom" ></TextInput>
            </b-col>
            <!-- <b-col style="align-self:center; padding-top:15px">
              <b-button variant="primary" @click="handleConjoint()" style="width:38px;height:38px">{{ existeConjoint ? "-" : "+"}}</b-button>
            </b-col> -->
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <TextInput id="nom_conjoint" type="text" label="Nom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.nom_conjoint" ></TextInput>
            </b-col>
            <b-col md="6">
              <TextInput id="prenom_conjoint" type="text" label="Prénom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.prenom_conjoint" ></TextInput>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse" ></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>            
            <b-col md="3">
              <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.codePostal" ></TextInput>             
            </b-col>
            <b-col md="6">
              <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville" ></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email" ></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.telephone" ></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.mobile" ></TextInput>                 
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <b-button variant="primary" @click="updateCustomer()">Enregistrer</b-button>
            </b-col>
          </b-form-row>

    
        </b-tab>
          <!-- <template #tabs-start>
            <b-form-select @change="onChange()" v-model="tabIndex">
              <b-form-select-option v-for="(bloc,index) in blocs" :key="bloc.id" :value="index">{{bloc.title}}</b-form-select-option>
                <template #first>
                  <b-form-select-option value="" disabled>-- Choisir --</b-form-select-option>
                </template>
            </b-form-select>
          </template> -->        
        <b-tab v-if="isLoaded" title="Photos" :title-link-class="linkClass('photo')" @click="selectedTab='photo'">
            <b-form-row>
              <b-col md="12">
               <b-list-group flush class="d-flex" v-for="doc in checklist" :key="doc.id_checklist" >
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1">{{doc.libelle_checklist}}</h6>
                    <span><b-badge :variant="etat(doc.valide)">{{doc.checklist_libelle_valide}}</b-badge></span>
                  </div>

                  <p class="mb-1" v-if="doc.valide != '1'">
                    {{doc.commentaire_rejet}}
                    <SingleFileUpload :id="doc.id_checklist" label="" :blocId="doc.id_checklist" :questionId=doc.id_checklist :item=doc></SingleFileUpload>
                  </p>
                
                </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-form-row>
          </b-tab>
          <!--<b-tab  v-for="bloc in blocs" :key="bloc.id" :title="bloc.title" :title-link-class="linkClass(blocs.id)" @click="selectedTab=bloc.id"-->
            <BlocItem v-for="bloc in blocs" :key="bloc.id" :bloc="bloc" :selectedTab="selectedTab" @clicked="onClicked"></BlocItem>
          <!--/b-tab-->


        


          <b-tab v-if="isLoaded" title="Dossier" :title-link-class="linkClass('dossier')" @click="selectedTab='dossier'">
            <b-form-row>
              <b-col md="12">
                <CreationDossier :informations="informations" :modelVT="modelVT" :itemRequired="true" @onCreated="resetVT()"/>
              </b-col>
            </b-form-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form>
    </validation-observer>
  </b-container>
</template>

<script>

import DossierService from '../services/DossierService'
import ClientService from '../services/ClientService'
import BlocItem from '../components/BlocItem'
import TextInput from '../components/TextInput'
//import json from '../models/model.json'
import GoBack from '../components/GoBack'
import CreationDossier from '../components/CreationDossier'
//import ModelVT from '../models/modelVT'
import SingleFileUpload from '../components/SingleFileUpload.vue'


export default {
  name: 'VT',
  components: {
    TextInput,
    CreationDossier,
    BlocItem,
    GoBack,
    SingleFileUpload
  },
  data: () => ({
      index: 1,
      tabIndex: 0,   
      selectedTab: "info",
      loading : false,
      show: true,
      blocs : [],
      existeConjoint: false,
      editMode: false,
      checklist : [],
      isLoaded: false
  }),
  mounted(){
    console.log(this.$route.params)
    console.log(this.blocs)
    this.blocs = this.modelVT.blocs

    this.loading = true
    DossierService.GetChecklist(1).then(result => {
      this.loading = false
      if(result.data.statut == 'OK') {
        this.checklist = result.data.liste
      }
    }).catch(error => {
      this.loading = false
      this.$bvModal.msgBoxOk(`Impossible de charger la liste de photo : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
    })
    
    if(this.$route.params && this.$route.params.idOrganisation) {
      ClientService.Customer(this.$route.params.idOrganisation).then(response => {
        console.log(response.data.data_orga)
        const data = response.data.data_orga

       this.informations.id = data.id_organisation,
       this.informations.nom = data.nom_contact,
       this.informations.prenom = data.prenom_contact
       this.informations.nom_conjoint = data.liv_contact,
       this.informations.prenom_conjoint = data.liv_contact_prenom,
       this.informations.adresse = data.adresse,
       this.informations.codePostal = data.code_postal,
       this.informations.ville =data.ville,
       this.informations.mobile=  data.num_tel_1,
       this.informations.telephone= data.num_tel_2,
       this.informations.email= data.email,
       this.informations.revenu1= 0,
       this.informations.nbPersonnes1= 0 ,
       this.informations.revenu2 = 0,
       this.informations.nbPersonnes2 = 0,
       this.informations.revenu3 = 0,
       this.informations.nbPersonnes3 = 0 ,   
       this.informations.zoneGeo =null,
       this.informations.categorieMPR = null,
       this.informations.id_categorieMPR = null,
       this.informations.id_categorieCEE = null,
       this.informations.numero_mpr= null
       this.informations.observation_dossier = null
       this.$store.dispatch("setClient", this.informations)
      }).catch(error => {
      this.loading = false
      this.$bvModal.msgBoxOk(`Erreur : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
      })
    }
    this.isLoaded = true
  },
   watch: {
    modelVT: {
      handler(){
        this.$store.dispatch("setModelVT", this.modelVT)
      },
      deep: true
    },
     informations: {
      handler(){
        this.$store.dispatch("setClient", this.informations)
      },
      deep: true
    },
   },
  computed: {
    informations () {
      return this.$store.getters.client
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    }
  },
  methods: {
    newCustomer(numTransaction) {
      return {
        id_organisation : this.$route.params.idOrganisation,
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
         typeop : 1,
         nom : this.informations.nom,
         prenom : this.informations.prenom,
         adresse : this.informations.adresse,
         codePostal : this.informations.codePostal,
         ville : this.informations.ville,
         id_pays : 1,
         mobile: this.informations.mobile,
         telephone: this.informations.telephone,
         email : this.informations.email,
  
        //Conjoint
         liv_raison_sociale : "",
         liv_adresse : this.informations.adresse,
         liv_code_postal : this.informations.codePostal,
         liv_ville : this.informations.ville,
         liv_fk_pays : 1,
         liv_contact : this.informations.nom_conjoint,
         liv_contact_prenom : this.informations.prenom_conjoint,
         liv_num_tel_1 : this.informations.mobile,
         liv_num_tel_2 : this.informations.telephone,
         liv_email : this.informations.email,
      }
    },
    async updateCustomer() {
      var numTransaction = Math.floor(Math.random() * 100000) + 1;
        var customer = this.newCustomer(numTransaction)
        let result = null
        try {
          result = await ClientService.UpdateCustomer(customer)
          // Creation du dossier
          if(result.status =="NOK") {
            console.log(result.message_specifique)
            if(result.error_number == 1062) {
              // le client existe deja
              
              // message : 
              this.$bvModal.msgBoxOk(`Erreur : Le client existe déjà`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
              return       
            }
            else {
              this.message = result.message_specifique
              return
            }
          }
          else {
            this.$bvModal.msgBoxOk('Client enregistré avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          }




        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le client : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
      

    },
    updateTabindex(index) {
      this.tabIndex = index
    },
      etat(id) {
        var str = ''
        switch (id) {
          case '1':
            str = 'success'
            break
          case '2':
            str = 'danger'
            break
          case '3':
            str = 'light'
            break
          case '0':
            str = 'warning'
            break
          default:
            str = ''
            break;
        }
        return str
      },
    linkClass(tab) {
      if (this.selectedTab === tab) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-primary']
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT() {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.$store.dispatch("viderDocuments")
      this.$router.push('/clients');
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    handleConjoint() {
      this.existeConjoint =! this.existeConjoint
      this.informations.nom_conjoint = ''
      this.informations.prenom_conjoint = ''
    },
    onClicked(val) {
      console.log(val)
      this.selectedTab = val
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
legend {
  font-size: 16px;
}
</style>
