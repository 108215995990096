<template>
  <span>
      <b-button @click="GoBack" variant="link"><b-icon icon="arrow-left-circle-fill" font-scale="1.5"></b-icon></b-button>
  </span>
</template>

<script>
export default {
    methods:{
        GoBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<style>

</style>