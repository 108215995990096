import ModelVT from '../../models/modelVT'

const state =  {
    modelVT:  new ModelVT()
  };
  
  const getters = {
    modelVT : state => { return state.modelVT}
  }
  
  const mutations = {
    SET_MODELVT(state, modelVT) {
        state.modelVT = modelVT
    }
  }
  
  const actions = {
    setModelVT( context , modelVT) {
          context.commit('SET_MODELVT', modelVT)
    }
  }
  
  export default {
      state, getters, mutations, actions
  }