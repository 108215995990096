// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import VueTheMask from 'vue-the-mask'
import VueSignature from "vue-signature-pad";

// Install vee-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("fr", fr);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueTheMask)
Vue.use(VueSignature)
Vue.config.productionTip = false

Vue.directive('uppercase', {
	update (el) {
		el.value = el.value.toUpperCase()
	},
})

Vue.filter('separators', function (value) {
  if (!value) return "0,00 €"
  return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value)
})

Vue.filter('percent', function (value) {
  if(!value) return "0,00 %"
  return new Intl.NumberFormat("de-DE", {style: "percent",maximumFractionDigits:2}).format(value)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
