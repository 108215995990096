import Api from '@/services/Api'
//import authHeader from './Auth-header';

function GetPrimeValue (data) {
    //return Api().get('catalogue/produits/' + id_organisation)
    console.log(data)
    return Api().post('Programme_aide/MontantAide', JSON.stringify(data))
}

function GetCategorieClient (data) {
  //return Api().get('catalogue/produits/' + id_organisation)
  console.log(data)
  return Api().post('Programme_aide/CategorieClient', JSON.stringify(data))
}


function GetProgrammeAide (typeDossier) {
  return Api().get('Programme_aide/ProgrammesAides/' + typeDossier)
}

function ActivationAide(data) {
  return Api().post('Programme_aide/ActivationAide', JSON.stringify(data))
}

export default {
  GetPrimeValue, GetProgrammeAide,ActivationAide, GetCategorieClient
}