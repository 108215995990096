<template>
  <div>

    
		<b-form-group v-for="(child, index) in list" :key="`child-${child.item.id_checklist}-${index}`" :label-for="`child-${child.item.id_checklist}-${index}`">
      <b-container fluid class="p-4 bg-light" v-show="child.showPreview">
        <b-row>
          <b-col>
            <b-img thumbnail rounded fluid :src="child.imagePreview" alt="Image 1"></b-img>
          </b-col>
        </b-row>
      </b-container>
      
      <b-input-group class="mt-3">
        <b-form-file v-model="child.file"
          placeholder="Choisir un fichier"
          drop-placeholder="Déplacer le fichier ici ..."
          @input="handleFileUpload(child, index)"
          ref="file" 
          browse-text="Choisir"
          :id="`child-${child.item.id_checklist}-${index}`"
        ></b-form-file>
        <b-input-group-append>
          <b-button variant="outline-danger" @click="clearFile(child)"><b-icon icon="x-circle"></b-icon></b-button>
          <b-button v-if="index > 0" @click="removeItem(index)" variant="danger" class="mr-2"><b-icon icon="trash"></b-icon></b-button>
        </b-input-group-append>
      </b-input-group>

		</b-form-group>

	<b-button v-if="multi && list.length > 0 && list[0].file != null" @click="addItem()" variant="primary" class="mr-2"><b-icon icon="plus-circle"></b-icon> Ajouter</b-button>
	
  </div>
</template>

<script>
import Document from "../models/document"
  
  export default {
    data(){
      return {
        list: []
      }
    },
    props : {
      item: Object,
      multi: {
        default: true,
        type: Boolean
      }
      },
    mounted () {
      this.list = [{
        item: this.item, 
        file : null,
        showPreview: false,
        imagePreview: '', 
        }]
    },
    methods: {

      addItem(){
        const copy = Object.assign({}, {
          item: this.item, 
          file : null,
          showPreview: false,
          imagePreview: '', 
        })
        this.list.push(copy)
      },
      removeItem(index) {
        if(index <= this.list.length) {
          this.list.splice(index, 1);
        }
      },
      clearFile(child) {
        child.file = null
        child.showPreview = false;
        child.imagePreview = null;
        this.$store.dispatch("supprimerDocument", child.item.id_checklist)
      },
      handleFileUpload(child, index){
        if(child.file == null) 
          return 

        var file = child.file;
        this.$store.dispatch("ajouterDocument", new Document(child.item.id_checklist, index, child.item.libelle_checklist, child.item.id_checklist, child.item.id_checklist, child.file, child.item.fk_categorie_attachement))

        let reader  = new FileReader();
        reader.addEventListener("load", function () {
          child.imagePreview = reader.result;
          child.showPreview = true;
        }.bind(this), false);

        if( file ){
          if ( /\.(jpe?g|png|gif)$/i.test( file.name ) ) {
            reader.readAsDataURL( file );
          }
        }
      }
    }
  }
</script>
<style scoped>
  div img{
    max-width: 200px;
    max-height: 200px;
  }
</style>