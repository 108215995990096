<template>
  <div id="app">
    <div>
  <b-navbar v-if="isLoggedIn" toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand href="#">
      <img src="./assets/logo-blanc-geslogis.png" class="d-inline-block align-center" style="width:104px" alt="GES" >
      
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to='/Clients'>Clients</b-nav-item>
        <!-- <b-nav-item to="/Questions">Questions</b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <em>{{user.login}}</em>
          </template>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" @click="logout()">Déconnexion</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
    <router-view/>
  </div>
</template>

<script>



export default {
  name: 'App',
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user
    }
  },
  methods : {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* margin-top: 60px; */
  background-color: #ffffff;
  background-image: repeating-linear-gradient(to bottom right, #2c6ca414 0%, #6cc3bd38 100%);
}

.card {

   border-radius: 1rem 0.25rem 0.25rem 0.25rem;
}
 
.nav-link {
    display: block;
    padding: 0.5rem 2rem;
    margin-right: 0.2rem;
    font-size: 1.2rem;
}
 
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
    border: solid #2c6ca4 0.1rem;
}
.text-light {
    color: #ffffff!important;
}

.card-header, .bg-primary {
  background-color: #2c6ca4;
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #2c6ca4), color-stop(1, #6cc3bd));
  background-image: -webkit-repeating-linear-gradient(top left, #2c6ca4 0%, #6cc3bd 100%);
  background-image: repeating-linear-gradient(to bottom right, #2c6ca4 0%, #6cc3bd 100%);
  background-image: -ms-repeating-linear-gradient(top left, #2c6ca4 0%, #6cc3bd 100%);
  color: #FFFFFF;
}

.card-header:first-child{
border-top-left-radius: 1em;
}

.btn-link {
font-weight: 400;
color: #2c6ca4 !important;;
}


.btn-primary {
color: #fff;
background-color: #6cc3bd !important;; 
border-color: #2c6ca4 !important;;
}

.border-primary {
 border-color: #2c6ca4 !important;
}

.btn-outline-primary {
    color: #2c6ca4 !important;;
    border-color: #2c6ca4 !important;;
}

.btn-outline-primary:hover {
    color: #fff;
  background-color: #6cc3bd !important;; 
  border-color: #2c6ca4 !important;;
}
.btn-outline-primary.active {
  color: #fff;
  background-color: #6cc3bd !important;; 
  border-color: #2c6ca4 !important;;
}

</style>
