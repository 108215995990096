export default class User {
    constructor(username, email, password) {
      this.username = username;
      this.email = email;
      this.password = password;
      this.firstname ="";
      this.lastname = "";
      this.id_organisation = 0;
      this.mode = "simple"
    }
  }