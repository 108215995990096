import Api from '@/services/Api'
import authHeader from './Auth-header';
import axios from 'axios'

function CreateFinancement (financement) {
  return Api().post('financement/create', financement, { headers: authHeader()})
}

function ListOrganismes() {
  return Api().get('financement/organismes', { headers: authHeader()})
}

function GetGrilleProjexio(ade, montant_credit, nombre_mensualite, report) {

  let url_simulateur = '' 
  switch (report) {
    case 6:
      url_simulateur = `https://www.simulateurcofidis.com/Projexio/API/index.php?key=Q8pe6qe4jSFJ6zd4JMSO3d1OULKYM3ri2NozfkmX8sWb4rfQu1&ade=${ade}&echance=${nombre_mensualite}&montant=${montant_credit}`
      break;
    case 0:
      url_simulateur = `https://www.simulateurcofidis.com/Projexio/API/index.php?key=8rkrQQzzqbXmB98dfCV2tqyEYPruM9oSGY7FgWBuyS2V4KLc3Z&ade=${ade}&echance=${nombre_mensualite}&montant=${montant_credit}`
      break;
    default:
      break;
  }

  return axios.get(url_simulateur)
}

function GetGrille(id_organisation, montant_credit, nombre_mensualite, report) {
  return Api().get('financement/grille/' + id_organisation + '/' + montant_credit + '/' + nombre_mensualite + '/' + report, { headers: authHeader()})
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //       resolve({
  //       id_orga_financier : id_organisation,
  //       montantCredit : montant_credit,
  //       nbEcheance : nombre_mensualite, // nbre_mois_mensualite
  //       report : report, // 0 ou 1
  //       dureeReport : 6,
  //       taeg : 4.93, // taux_global
  //       taux : 4.85, // taux
  //       montantEcheanceHorsAssurance : 87.26, //montant_echeance_hors_assurance
  //       montantEcheanceAvecAssurance : 90.86, //montant_echeance_avec_assurance
  //       coutTotalHorsAssurance : 1047.12, // cout_total_hors_assurance
  //       coutTotalAvecAssurance : 1090.32, // cout_total_avec_assurance
  //       `montant_mensualites_hors_ass` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00,
  //       `pourcent_taux_debiteur_fixe` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00,
  //       `TAEG` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00,
  //       `frais_dossier` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00,
  //       `montant_total_emprunteur_hors_ass` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00,
  //       `cout_mensuel_ass_1` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'Mon ass. De pers. Premium',
  //       `cout_mensuel_ass_2` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'Mon ass. De pers. Confort ou Sécurité senior (2)',
  //       `cout_total_ass_1` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'Mon ass. De pers. Premium',
  //       `cout_total_ass_2` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'Mon ass. De pers. Confort ou Sécurité senior (2)',
  //       `mensualite_ass_confort_emprunteur_1` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'emprunteur ou coemprunteur',
  //       `mensualite_ass_confort_emprunteur_2` decimal(10,2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT 'emprunteur ou coemprunteur',
  //     })
  //   }, 2000)
  //})
}

export default {
  CreateFinancement, ListOrganismes, GetGrille, GetGrilleProjexio
}