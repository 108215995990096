import Api from '@/services/Api'
import authHeader from './Auth-header';
import store from '../store/index'

function CreateDossier (dossier) {
  console.log(dossier)
  return Api().post('dossier_affaire/create', dossier, { headers: authHeader()})
  //return Promise.resolve( {status : "OK", message_specifique:"", data:{id_fiche:1}})
}

function CreateArticles (dossierArticles) {
  console.log(dossierArticles)
  return Api().post('dossier_affaire/create_articles', dossierArticles, { headers: authHeader()})
  //return new Promise(resolve => setTimeout(resolve, 1000))
}

function CreateVT(VT) {
  console.log(VT)
  return Api().post('Vt/create', VT, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:33}})
}

function SendDocument(doc) {


  var FormData = require('form-data');
  var data = new FormData();

  data.append('code_app', doc.code_app);
  data.append('numTransaction', doc.numTransaction);
  data.append('id_user_membre', doc.id_user_membre);
  data.append('module', doc.module);
  data.append('id_fiche', doc.id_fiche);
  data.append('titre_doc', '');
  data.append('id_categorie_fichier', doc.id_categorie_fichier)
  data.append('description_doc', doc.description_doc)
  data.append('VT_Id', doc.VT_Id);
  data.append('VT_JSchedule_api', doc.VT_JSchedule_api);
  data.append('VT_blockId', doc.VT_blockId);
  data.append('VT_blocklIteration', doc.VT_blocklIteration);
  data.append('VT_comment', doc.VT_comment);
  data.append('VT_itemId', doc.VT_itemId);
  data.append('userfile', doc.file);
  data.append('bloc_portlet', doc.bloc_portlet);
  data.append('id_bloc_portlet', doc.id_bloc_portlet);

  return Api().post('/document/upload' , data, 
  {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function( progressEvent ) {
      store.dispatch('majProgression', { id:doc.id_bloc_portlet, uploadPercentage : parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))})
    }.bind(this)
  })
}

function GetVT(idUser, idVT) {
  console.log(idVT)

  return Api().get('Vt/get/APP_COMMERCIALE/'+ idUser + '/DOSSIER_AFFAIRE/' + idVT , { headers: authHeader()})
}

function GetObliges() {

  // const obliges = {data :{
  //     statut: 'OK',
  //     liste : [ 
  //       {id_organisation: 1, libelle_organisation:"GES"},
  //       {id_organisation: 2738, libelle_organisation:"AXDIS PRIME"},
  //       {id_organisation: 7321, libelle_organisation:"Premium Energy"},    
  //     ]
  // } 
  // }

  //return Promise.resolve(obliges)
  return Api().get('dossier_affaire/donneursOrdre/GRILLE' , { headers: authHeader()})
}

function GetTypes() {

  // { value: 'pv', text: 'Photovoltaïque' },
  // { value: 'pacRR', text: 'PAC Air/Air' },
  // { value: 'pacRO', text: 'PAC Air/Eau' },
  //{ value: '11', text: 'PAC Air/Eau (VAD)' },
  // { value: 'mixte', text: 'Mixte'},
  //{ value: '10', text: 'AMO'}

  //return Promise.resolve([{id_type_dossier: 10, libelle_type_dossier:"AMO"}, {id_type_dossier: 11, libelle_type_dossier:"PAC VAD"}])
  return Api().get('dossier_affaire/typeDossierAffaire/1' , { headers: authHeader()})
}

function GetProgrammesAides(id_type_dossier) {
  //return Promise.resolve([{id_programme_aide: id_type_dossier, titre:"ANHA"}, {id_programme_aide: 12, titre:"CDP"}])
  return Api().get('dossier_affaire/programmesAides/' + id_type_dossier , { headers: authHeader()})
}

function GetMontantAide(id_programme_aide, revenu_fiscal, nb_foyer, cp) {
  //return Promise.resolve({categorie: id_programme_aide, montant:revenu_fiscal + nb_foyer + cp})
  return Api().get('dossier_affaire/montantAide/' + id_programme_aide + '/' + revenu_fiscal + '/' + nb_foyer  + '/' + cp , { headers: authHeader()})
}

function ActivationAide(aides) {
  //var aides = [{id_programme_aide:1, montant:0, revenu_fiscal:0, nb_foyer:0, categorie:""}]
  return Api().post('dossier_affaire/activationAide/', aides , { headers: authHeader()})
}


function UpdateVT(VT) {
  console.log(VT)

  return Api().post('Vt/update', VT , { headers: authHeader()})
}

function GetChecklist (typeDossier) {
  return Api().get(`Checklist/checklist/${typeDossier}` , { headers: authHeader()})
  //return new Promise(resolve => setTimeout(resolve, 1000))
}

export default {
    CreateDossier, CreateArticles, CreateVT, GetVT, SendDocument, UpdateVT, GetTypes, GetProgrammesAides,GetMontantAide,ActivationAide,GetObliges,GetChecklist
}