<template>
  <div>
      <b-form-row>
        <b-col md="12" >
          <b-alert :show="message !=''" variant="danger" class="text-center">{{message}}</b-alert>
        </b-col>
      </b-form-row>
      <b-form-row >
        <b-col md="12">
          <b-alert :show="idDossier == 0 && informations.revenu1 == 0 && informations.nbPersonnes1 == 0" variant="warning">
            <h6 class="alert-heading">Veuillez renseigner les informations fiscales</h6>
          </b-alert>
        </b-col>
      </b-form-row>
      <b-form-row >
        <b-col md="12">
          <b-alert :show="idDossier == 0 &&  informations.numero_mpr == null" variant="warning">
            <h6 class="alert-heading">Veuillez renseigner le numéro MPR</h6>
          </b-alert>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12" v-if="idDossier != 0">
          <b-alert  :show="modelVT.validation_errors.length > 0" variant="warning">
            <h6 class="alert-heading">Veuillez renseigner les questions ci-dessous</h6>
            <ul>
              <li v-for="(error, index) in modelVT.validation_errors" :key="index">{{error}}</li>
            </ul>
          </b-alert>
        </b-col>
      </b-form-row>
      <b-form-row v-if="idDossier != 0">
        <b-col md="12">
          <h6 class="mb-1">{{item.libelle_checklist}}</h6>
          <SingleFileUpload id="99" label="Fichier RENO AUDIT" blocId="99" questionId="99" :multi="false" :item="item" v-model="reno"></SingleFileUpload>
        </b-col>
      </b-form-row>
      <b-form-row  v-if="idDossier == 0">
        <b-col>
          <b-form-checkbox id="checkbox-1" v-model="collecte" name="checkbox-3"  :disabled="disableConfirmCheck">
          {{ textCollecte }}
         </b-form-checkbox>
         <b-form-checkbox id="checkbox-2" v-model="cgv" name="checkbox-2"  :disabled="disableConfirmCheck">
            {{textCgv }}
         </b-form-checkbox>
         <b-form-checkbox id="checkbox-3" v-model="partenaire" name="checkbox-3"  :disabled="disableConfirmCheck">
          {{ textPartenaire }}
         </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
         <p class="mb-2 mt-4"> Merci de bien relire les informations du client, le dossier ne peux etre modifié dans l'application</p>
         <p class="mb-2 mt-4 ">Geslogis, filiale de GES Group traite les données de ses clients pour gérer les simulations associées et personnaliser le rapport d’audit énergétique.
Pour en savoir plus sur la manière dont nous gérons les données à caractère personnel, veuillez consulter la rubrique « <a href="https://geslogis.fr/vie-prive.html" target="_blank">Vos données et vos droits</a> »
          </p>

        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12" class="mt-5">
           <b-button block variant="primary" v-if="showCreateButton" @click="creerDossier" :disabled="disabled">{{labelButton}}</b-button>
        </b-col>
      </b-form-row>
      <div v-show="createGlobal">
        <!-- <b-form-row class="mt-2" v-if="this.idClient == '0'">
          <b-col md="5" >
            <span :class="customerCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation du client</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="customerCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="customerCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row> -->
        <b-form-row class="mt-2" v-if="this.idDossier == '0'">
          <b-col md="5" >
            <span :class="contractCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation du dossier</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="contractCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="contractCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-2" v-if="documents.length > 0">
          <b-col md="5">
            <span :class="photoCreation ? 'font-weight-bold' : 'font-weight-normal'">Envoie des documents au serveur</span>
          </b-col>
        </b-form-row>
         <b-form-row class="mt-2" v-if="documents.length > 0">
          <b-col md="12">
            <b-list-group>
              <b-list-group-item v-for="doc in documents" :key="doc.internalId" class="d-flex justify-content-between align-items-center">
                <div class="d-flex w-100 justify-content-between">
                {{doc.title}} : {{doc.file.name}} 
                <progress v-show="doc.uploadPercentage != 100" max="100" :value.prop="doc.uploadPercentage"></progress>
                <b-icon v-show="doc.uploadPercentage == 100" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
                <b-icon v-show="doc.status == 'ERROR'" icon="x-circle" variant="danger" font-scale="1"></b-icon>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-form-row> 
        <b-form-row class="mt-2" v-if="idDossier != 0">
          <b-col md="5">
            <span :class="vtCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation de la visite technique</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="vtCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="vtCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>
         <b-form-row class="mt-2" v-if="itemRequired && idDossier == 0">
          <b-col md="5">
            <span :class="orderCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation des articles</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="orderCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="orderCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-2" v-if="idDossier == 0">
          <b-col md="5">
            <span :class="aidesCreation ? 'font-weight-bold' : 'font-weight-normal'">Activation des aides</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="aidesCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="aidesCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>  
        <!-- <b-form-row class="mt-2">
          <b-col md="5">
            <span :class="financementCreation ? 'font-weight-bold' : 'font-weight-normal'">Financement</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="financementCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="financementCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>  -->
      </div>
  </div>
</template>

<script>
import AideService from '../services/AideService'
//import ClientService from '../services/ClientService'
import DossierService from '../services/DossierService'
//import FinancementService from '../services/FinancementService'
//import FacturationService from '../services/FacturationService'
import SingleFileUpload from '../components/SingleFileUpload.vue'

export default {
  name: 'CreationDossier',
  components: {
    SingleFileUpload
  },
  props: ["modelVT","itemRequired"],
  data: () => ({
    show : false,
    message : '',
    collecte : false,
    textCollecte : "Je reconnais avoir donné mon consentement préalable à la collecte de mes données personnelles.",
    cgv : false,
    textCgv : "Je reconnais avoir pris connaissance et accepté les CGV relatives à l'Offre proposée par GES LOGIS.",
    partenaire: false,
    textPartenaire: "Je reconnais avoir accepté la mise en relation avec les partenaires de GES LOGIS.",
    createGlobal : false,
    customerCreation : false,
    contractCreation : false,
    orderCreation : false,
    customerCreated : false,
    contractCreated : false,
    orderCreated : false,
    vtCreation : false,
    vtCreated : false,
    photoCreation : false,
    photoCreated : false,
    aidesCreation : false,
    aidesCreated : false,
    financementCreation : false,
    financementCreated : false,
    errors:[],
    reno: null,
    disabled: false,
    item: {
      id_checklist: 99,
      fk_categorie_attachement: "58",
      type_check: "UPLOAD",
      is_check_app: 1,
      id_code_checklist: 1,
      info_code_checklist: "AUDIT ENR - RENOV GLOBALE",
      categorie_checklist: "DOSSIER_AFFAIRE",
      bool_checklist_obligatoire: 0,
      libelle_checklist: "Fichier RENO AUDIT",
      criteres: "Obligatoire",
      statut_check_en_creation: 0,
      valide_item_checklist: 1,
      ordre_affichage: 100
  }
  }),
  mounted() {
    
  },
  computed: {
    id_categorieMPR() {
      return this.informations.id_categorieMPR
    },
    informations () {
      return this.$store.getters.client
    },
    showCreateButton() {
      this.modelVT?.isValid()
      const confirmed = this.collecte && this.cgv && this.partenaire
      if (confirmed) {
        this.$emit('onConfirmed', `${this.textCollecte}\n${this.textCgv}\n${this.textPartenaire}`)
      }
      return this.idDossier != 0 || confirmed
    },
    disableConfirmCheck() {
      return this.idDossier == 0 &&  (this.informations.numero_mpr == null
      || this.informations.revenu1 == 0 
      || this.informations.nbPersonnes1 == 0)
    },
    labelButton() {
      return this.idDossier != "0" ? "Actualisation du dossier d'affaire" : "Creation du dossier d'affaire"
    },
    id_user_membre() {
      return this.$route.params && this.$route.params.id_user_membre || this.$store.getters.user.id_user_membre
    },
    selectedPacks() {
      return this.$store.getters.selectedPacks
    },
    documents() {
      return this.$store.getters.documents
    },
    aides() {
      return this.$store.getters.aides
    },
    selectedAides() {
      return this.$store.getters.selectedAides
    },
    filteredAides() {
      return this.selectedAides.filter(a => a.prime > 0 || a.id == "3" || a.id == "14")
    },
    totalAides() {
      return this.$store.getters.totalAides
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    },
    financement() {
      return this.$store.getters.financement
    },
    totalTTC () {
      return this.$store.getters.totalTTC
    },
    idDossier() {
      return this.$route.params && this.$route.params.idDossier || 0
    },
    idClient() {
      return this.$route.params && this.$route.params.id_client || 0
    },
    packs () {
      return this.$store.getters.packs
    },
  },
  watch: {
    id_categorieMPR() {
        this.calculAides()
    }
  },
  methods: {
    newCustomer(numTransaction) {
      return {
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
        typeop : 1,
        statutOrga : 2,
        id_origine_affaire_ecommerce : 500,
        id_secteur_organisation : 0,
        nom : this.informations.nom,
        prenom : this.informations.prenom,
        adresse : this.informations.adresse,
        codePostal : this.informations.codePostal,
        ville : this.informations.ville,
        id_pays : 1,
        mobile: this.informations.mobile,
        telephone: this.informations.telephone,
        email : this.informations.email,
        zone : this.informations.zoneGeo,
        //Conjoint
        liv_raison_sociale : "",
        liv_adresse : this.informations.adresse,
        liv_code_postal : this.informations.codePostal,
        liv_ville : this.informations.ville,
        liv_fk_pays : 1,
        liv_contact : this.informations.nom_conjoint,
        liv_contact_prenom : this.informations.prenom_conjoint,
        liv_num_tel_1 : this.informations.mobile,
        liv_num_tel_2 : this.informations.telephone,
        liv_email : this.informations.email
      }
    },
    newDossier(numTransaction, titre_dossier, id_organisation_client) {

      let categorie_revenu = "TRES_MODESTES"

      switch (this.informations.id_categorieMPR) {
        case "1":
          categorie_revenu = "TRES_MODESTES"
          break;
        case "2":
          categorie_revenu = "MODESTES"
          break;
        case "3":
          categorie_revenu = "INTERMEDIAIRES"
          break;
        case "4":
          categorie_revenu = "ELEVES"
          break;
        default:
          break;
      } 

      return {
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            id_organisation_client : id_organisation_client,
            id_type_dossier : 1,//this.informations.typeDossier,
            id_regie : null,//this.informations.idRegie,
            titre_dossier : titre_dossier,
            bool_qui_facturer : 0,
            id_moe : 1,//this.oblige.id_organisation, // envoyer l'obligé
            jsonORGAString : "",
            bloc_dossier_num_1 : "0",
            bloc_dossier_num_2 : this.totalTTC,
            revenu_fiscal_1: this.informations.revenu1, 
            revenu_fiscal_2: this.informations.revenu2, 
            revenu_fiscal_3: this.informations.revenu3, 
            nombre_personne: this.informations.nbPersonnes1 + this.informations.nbPersonnes2 + this.informations.nbPersonnes3, 
            categorie_revenu: categorie_revenu,
            observation_dossier : this.informations.observation_dossier,
            json_declaration : JSON.stringify(this.informations.declaration)
          }
    },

    newVT(numTransaction, idDossier) {
      return {
          code_app : "APP_COMMERCIALE",
          numTransaction : numTransaction,
          id_user_membre : this.id_user_membre,
          module: "DOSSIER_AFFAIRE",
          id_model_VT : 2,
          idOrganisation : 0,
          idDossier : idDossier,
          modeVT : "VIEW",
          batiment : "",
          detail_emplacement : "",
          jsonVtString : JSON.stringify(this.modelVT.blocs)
        }
    },
    newArticles(numTransaction, idDossier, articles) {
      return {
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            idDossier : idDossier,
            articles : articles
          }
    }, 

    handleProgress(doc) {
      return {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          doc.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
        }.bind(this)
  }
    },
    async calculAide(aide, gammeId) {
      

      return new Promise((resolve) => {
        //if(simple) this.loading = true

            let organisation = null 

            //Si obligé choisi dans la liste on le prend sinon on prend l'oblige par defaut
            let res = this.oblige ? aide.organisations.find(x=>  x.fk_organisation== this.oblige.id_organisation) : null
            if(res) {
              organisation = res
            }
            else {
              res  = aide.organisations.find(o=> o.is_defaut_grille == "1")
              if(res) {
                organisation = res
              }
            }

            // Calcul des aides
            if(organisation) {
              var data = {
                code_app : "APP_COMMERCIALE",
                numTransaction : Math.floor(Math.random() * 100000) + 1,
                id_user_membre : this.id_user_membre,
                id_programme_aide : aide.id,
                revenu_fiscal : this.totalRevenu,
                nombre_personne : this.totalPersonnes,
                cp : this.informations.codePostal ? this.informations.codePostal.substring(0,2) : "0",
                gamme : gammeId,
                fk_organisation : organisation.fk_organisation,
                raison_sociale : organisation.raison_sociale
              }
              
              resolve(this.$store.dispatch("GetPrimeValue", data))
              // .then(() => {
              //   if(simple) { this.loading = false} 
              // }).catch(() => this.loading = false
              // );
            }
            else {
              resolve()
            }
      })

    },
    async calculAides() {

      //80 BLEU
      //81 JAUNE
      //82 Violet
      //83 ROSE

      const MPR = this.informations.id_categorieMPR
      let gammeId = 0

      switch (MPR) {
        case "1":
          gammeId = 80;
          break;
        case "2":
          gammeId = 81;
          break;
        case "3":
          gammeId = 82
          break;
        case "4":
          gammeId = 83
          break;      
        default:
          gammeId = 0
          break;
      }

      this.loading = true
      this.firstLoading = false
      let this_ = this
      this.$store.dispatch("ResetAides").then(() => {
        let programme = this.aides//.filter(x => { return x.grille != null})
        if(programme) {
          var promises = programme.map(aide => {
             return this_.calculAide(aide, gammeId)
          });

          Promise.all(promises.map(p => p.catch(e => e))).then( () => {
            this.loading = false
          });
        }
      })
    },
    async creerDossier() {

      try {
        this.disabled = true
        let result = null
        this.message = ""
        this.show = false;
        if(this.informations.nom == '' || this.informations.prenom =='' || this.informations.adresse == '' || this.informations.codePostal == null
        && this.informations.ville == '' || this.informations.mobile == null) {
          this.show = true
          this.message = 'Merci de remplir les informations clients'
          return
        }

        this.createGlobal=true
        this.customerCreation = true
        let idDossier = 0
        let VT_Id = 0
        var numTransaction = Math.floor(Math.random() * 100000) + 1;
        //var customer = this.newCustomer(numTransaction)
        let idFiche = 0



        console.log(this.informations.id)
        idFiche = this.informations.id


        this.customerCreation = false
        this.customerCreated = true
        this.contractCreation = true      

        if(this.idDossier == '0') {
          

          let titre_dossier = `${this.informations.nom} ${this.informations.prenom}`
          
          var dossier = this.newDossier(numTransaction, titre_dossier, idFiche);
    
          result = await DossierService.CreateDossier(dossier)
          if(result.status =="NOK") {
            this.message = result.message_specifique
            return
          }
          idDossier = result.data.id_fiche;

          //Creation des articles
          this.contractCreation = false
          this.contractCreated = true
          this.orderCreation = true      
            
          var articles = [];

          let MPR = ""
          switch (this.informations.id_categorieMPR) {
            case "1":
              MPR = "Bleu"
              break;
            case "2":
              MPR = "Jaune"
              break;
            case "3":
              MPR = "Violet"
              break;
            case "4":
              MPR = "Rose"
              break;
            default:
              break;
          }

          
          const produit = this.packs.find(x=> x.gamme.includes(MPR))
          console.log(produit.articles[0])

          articles.push({id_article : produit.articles[0].id, prixVenteHT: produit.articles[0].prixHT, qte:1})

          var dossierArticles = this.newArticles(numTransaction, idDossier, articles) 

          result = await DossierService.CreateArticles(dossierArticles)
          if(result.status =="NOK") {
              this.message = result.message_specifique
              return
          }

          
          this.orderCreation = false
          this.orderCreated = true
          this.aidesCreation = true


          var aides = this.selectedAides.map(a => { 
            return { 
              id_programme_aide: a.id, 
              fk_organisation: a.oblige.fk_organisation, 
              revenu_fiscal_1: this.informations.revenu1, 
              revenu_fiscal_2: this.informations.revenu2, 
              revenu_fiscal_3: this.informations.revenu3, 
              nombre_personne: this.informations.nbPersonnes1 + this.informations.nbPersonnes2 + this.informations.nbPersonnes3, 
              montant_aide: a.prime, 
              bool_regle_client: a.deductible ? 0 : 1,
              ref_client :  this.informations.numero_mpr // Numero MPR
            } 
          })

          var data = {
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            idDossier : idDossier,
            programmes_aides: aides
          }
       
          result = await AideService.ActivationAide(data)
          if(result.status =="NOK") {
              console.log(result.message_specifique)
          }
          this.aidesCreation = false
          this.aidesCreated = true
        }
        else {
          idDossier = this.idDossier


          this.vtCreation = true
  
          result = await DossierService.CreateVT(this.newVT(numTransaction,idDossier ))
          if(result.status =="NOK") {
            this.message = result.message_specifique
          }

          
          this.photoCreation = true
          // envoi des photos

          VT_Id = result.data.id_fiche
          var _this = this
          let requests = []
          for (let index = 0; index < _this.documents.length; index++) {
            
            let doc = 
            {
              code_app : "APP_DOSSIER",
              numTransaction : numTransaction,
              id_user_membre : _this.id_user_membre,
              module: "DOSSIER_AFFAIRE",
              id_categorie_fichier : _this.documents[index].index != 0 ||  _this.documents[index].categoryId == 58 ? _this.documents[index].categoryId : null,
              titre_doc : "",
              description_doc : _this.documents[index].title,
              id_fiche : idDossier,
              VT_blockId : _this.documents[index].blockId,
              VT_itemId : _this.documents[index].questionId,
              file: _this.documents[index].file,
              bloc_portlet : "da_bloc_checklist", // vide si  id_categorie_fichier renseigné
              id_bloc_portlet : _this.documents[index].id, // vide si id_categorie_fichier renseigné
            }
            console.log(doc)
          
            
            requests.push(
              DossierService.SendDocument(doc)
              .then((result) => {
    
                if(result.status =="NOK") {
                  console.log(result.message_specifique)
                }
                else {
                  doc.status = 'SENT'
                  //_this.blocs[result.data.VT_blockId].questions[result.data.VT_itemId].value = result.data.nom_docWithExt
                  console.log(doc)
                }

              }).catch((error) => {
                
                console.log(error)
                doc.status = 'ERROR'
              })
            )
          }
          await Promise.all(requests.map(p => p.catch(e => e)))

          this.photoCreated = true

        
          result = await DossierService.UpdateVT({
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            module: "DOSSIER_AFFAIRE",
            id_VT : VT_Id,
            batiment : "",
            detail_emplacement : "",
            jsonVtString : JSON.stringify({informations : this.informations, blocs : this.modelVT.blocs, aides:aides})
          })
          if(result.status =="NOK") {
              console.log(result.message_specifique)
          }

          this.vtCreation = false
          this.vtCreated = true
        } 
          this.$emit("onCreated", idDossier);
          
        } catch (error) {
          console.log(error)
          this.message = error.response.data.message_specifique || 'Une erreur est survenue. Merci de contacter le support'
        }
      }      
    }
  }

</script>

<style scoped>
p {
  font-size: 0.8em;
}
</style>