<template>
  <div>
        <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
        <SingleFileUpload v-if="question.question_type =='uploadDoc'" :id="question.id" :label="question.title" :blocId="blocId" :questionId=question.id :multi="question.multi"></SingleFileUpload>
      
        <div v-if="question.question_type == 'datepicker'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-input :id="question.comment" type="text" v-model="innerValue.value" ></b-form-input>
          </b-form-group>
        </div>

        <div v-if="question.question_type == 'inputText'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-input :id="question.comment" :type="question.value_type" v-model="innerValue.value" ></b-form-input>
          </b-form-group>
        </div>

        <div v-if="question.question_type == 'textarea'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-textarea :id="question.comment" v-model="innerValue.value"></b-form-textarea>
          </b-form-group>
        </div>

        <div v-if="question.question_type == 'radio'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-radio-group buttons button-variant="outline-primary" :id="question.comment" v-model="innerValue.value"  :options="question.list_values"></b-form-radio-group>
          </b-form-group>
        </div>
      
        <div v-if="question.question_type == 'select'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-select :id="question.comment" v-model="innerValue.value"  :options="question.list_values">
              <template #first>
                <b-form-select-option value="" disabled>-- Choisir --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>

        <div v-if="question.question_type == 'checklist'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-checkbox-group :id="question.comment" v-model="innerValue.value"  :options="question.list_values" stacked></b-form-checkbox-group>
          </b-form-group>
        </div>

        <div v-if="question.question_type == 'calcul'">
          <h6>{{question.value}}</h6>
        </div>

        <div v-for="sub_question in question.sub_questions" :key="sub_question.id">
          <b-row v-if="question.value==sub_question.selected_parent_value">
            <b-col cols="1"><b-icon icon="arrow-right-square-fill"></b-icon></b-col>
            <b-col cols="11">
          
          <SingleFileUpload v-if="sub_question.question_type =='uploadDoc'" :id="sub_question.id" :label="sub_question.title" :blocId="blocId" :sub_question=question.id ></SingleFileUpload>

          <div v-if="sub_question.question_type == 'inputText' && question.value==sub_question.selected_parent_value" class="sub_question">
            <b-form-group :label="sub_question.title" :label-for="sub_question.comment">
              <b-form-input :id="sub_question.comment" :type="sub_question.value_type" v-model="sub_question.value" ></b-form-input>
            </b-form-group>
          </div>

        <div v-if="sub_question.question_type == 'textarea' && question.value==sub_question.selected_parent_value" class="sub_question">
          <b-form-group :label="sub_question.title" :label-for="sub_question.comment">
            <b-form-textarea :id="sub_question.comment" v-model="sub_question.value"></b-form-textarea>
          </b-form-group>
        </div>
        
          <div v-if="sub_question.question_type == 'radio' && question.value==sub_question.selected_parent_value" class="sub_question">
            <b-form-group :label="sub_question.title" :label-for="sub_question.comment">
              <b-form-radio-group buttons button-variant="outline-primary" :id="sub_question.comment" v-model="sub_question.value"  :options="sub_question.list_values"></b-form-radio-group>
            </b-form-group>
          </div>
        
          <div v-if="sub_question.question_type == 'select' && question.value==sub_question.selected_parent_value" class="sub_question">
            <b-form-group :label="sub_question.title" :label-for="sub_question.comment">
              <b-form-select :id="sub_question.comment" v-model="sub_question.value"  :options="sub_question.list_values">
                <template #first>
                <b-form-select-option value="" disabled>-- Choisir --</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </div>

          <div v-if="sub_question.question_type == 'checklist' && question.value==sub_question.selected_parent_value" class="sub_question">
            <b-form-group :label="sub_question.title" :label-for="sub_question.comment">
              <b-form-checkbox-group :id="sub_question.comment" v-model="sub_question.value"  :options="sub_question.list_values" stacked></b-form-checkbox-group>
            </b-form-group>
          </div>

          <div v-if="sub_question.question_type == 'calcul' && question.value==sub_question.selected_parent_value" class="sub_question">
            <h6>{{sub_question.title}} : {{ calcul(question) }}</h6>
          </div>
          </b-col>
          </b-row>
        </div>

        <!-- <b-form-group v-if="blocs[0].questions[22].value==blocs[0].questions[22].sub_questions[0].selected_parent_value" id="autreMateriau-grp" :label="blocs[0].questions[22].sub_questions[0].title" :label-for="blocs[0].questions[22].sub_questions[0].comment">
            <b-form-input :id="blocs[0].questions[22].sub_questions[0].comment" :type="blocs[0].questions[22].sub_questions[0].value_type" v-model="blocs[0].questions[22].sub_questions[0].value"></b-form-input> 
        </b-form-group> -->
  </div>
</template>

<script>
import SingleFileUpload from "../components/SingleFileUpload"


export default {
  name: 'MQuestionItem',
  components: {
      SingleFileUpload
  },
  props: ['question','blocId'],
  data: () => ({
     innerValue: ""
  }),
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    question(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  created() {
    if (this.question) {
      this.innerValue = this.question;
    }
  },
  methods: {
    calcul(question) {
      let res = 1
      let field = question.sub_questions.filter(x=> x.question_type =='calcul')
      if(field && field[0].default_value) {
        // recherche de l'operateur *
        if(field[0].default_value.includes("*")) {
          let values = field[0].default_value.split('*').filter(x=> x != '*')
          
          values.forEach(element => {
            let q = question.sub_questions.find(x=> x.title.trim() == element.trim())
            if(q) {
              res *= q.value
            }
          });
        }

        // recherche de l'operateur +
        if(field[0].default_value.includes("+")) {
          let values = field[0].default_value.split('+').filter(x=> x != '+')
          
          values.forEach(element => {
            let q = question.sub_questions.find(x=> x.title.trim() == element.trim())
            if(q) {
              res += q.value
            }
          });
        }
      }
      field.value = res
      return res
    }
  }
}
</script>

<style>
.sub_question {
  border: 0px black solid;
  margin-left: 0rem;
}
</style>