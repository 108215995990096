<template>
<div  class="mt-5 d-flex flex-row" v-if="idDossier != 0">
  <div>
    <b-button variant="primary" class="mr-2" @click="generateDevis()">Générer le devis <b-icon v-show="success" icon="check-circle-fill" variant="info" font-scale="1"></b-icon> </b-button>
  </div>
  <!-- <b-button v-if="facturationId != 0" variant="primary" @click="editerDevis(facturationId)">Editer devis <b-icon v-show="success" icon="check-circle-fill" variant="info" font-scale="1"></b-icon> </b-button> -->
  
  <div>
    <a v-if="url != ''" :href="url" target="_blank"> <button type="button" class="btn btn-outline-primary mr-2">Visualiser le devis</button></a>
  </div>
  
 <div>
    <b-button v-if="url != ''" variant="primary" @click="validerDevis()" :disabled="loading">
      <b-spinner v-if="loading" small></b-spinner> Valider le devis
    </b-button>
  </div>

  <b-modal id="modal-confirm" size="xl" title="Validation du devis">
    <pdf :src="url"></pdf>
  </b-modal>

</div>
</template>

<script>
import FacturationService from '../services/FacturationService'
import DocumentService from '../services/DocumentService'
import pdf from 'vue-pdf'

export default {
  name: 'MFacturation',
  props : ['idDossier', 'observations'],
  components: {
    pdf
  },
  data: () => ({
    loading: false,
    success: false,
    facturationId : 0,
    url: ""
  }),
  computed: {
    id_user_membre() {
      return this.$store.getters.user.id_user_membre
    },
  },
  methods: {
    async validerDevis() {
      this.loading = true
      let data = {
            code_app : "APP_COMMERCIALE",
            numTransaction : Math.floor(Math.random() * 100000) + 1,
            id_user_membre : this.id_user_membre,
            id_facturation : this.facturationId,
            id_piece_facturation : 3,
            modeEnvoi : 2
        }

      try {
          await FacturationService.EnvoiFacturation(data)
          await this.envoiMPR()
          this.success = true
          this.loading = false

          this.$router.push("/clients")

          //this.$bvModal.show('modal-confirm')
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
        }
    },
    async editerDevis(id) {
      this.loading = true
      let data = {
            code_app : "APP_COMMERCIALE" ,
            numTransaction  : Math.floor(Math.random() * 100000) + 1,
            id_user_membre  : this.id_user_membre,
            id_facturation : id
        }
    
        try {
          const result = await FacturationService.EditFacturation(data)
          this.url = result.data.pathDoc.replace("/var/www/vhosts/","https://").replace("httpdocs/","")

          this.success = true
          this.loading = false

          //this.$bvModal.show('modal-confirm')
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
        }
        
    },
    async generateDevis() {
      this.loading = true
      let data = {
            code_app : "APP_COMMERCIALE",
            numTransaction :  Math.floor(Math.random() * 100000) + 1,
            id_user_membre : this.id_user_membre,
            id_piece_facturation : 3,
            id_dossier : this.idDossier,
            observation : this.observations,
            recommandation : ""
        }

      try {
        const result = await FacturationService.CreateFacturation(data)
        this.facturationId = result.data.id_fiche
        await this.editerDevis(this.facturationId)
       
        this.success = true
        this.loading = false

        console.log("devis", this.facturationId, this.url)
      } catch (error) {
        this.loading = false
        this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
      }
    },
    async envoiMPR() {
      this.loading = true
      let data = {
        code_app : "APP_COMMERCIALE",
        numTransaction : Math.floor(Math.random() * 100000) + 1,
        id_user_membre : this.id_user_membre,
        module: "DOSSIER_AFFAIRE",
        id_fiche : this.idDossier,
        id_modele_pdf : 4,
        modeEnvoi : 2
      }

      try {
          await DocumentService.SendDocument(data)

          this.success = true
          this.loading = false

        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
        }
    },
  }
}
</script>

<style>

</style>