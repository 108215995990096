import Piece from '../../models/piece'

const state =  {
    pieces: [ new Piece("Piece 1")],
    totalDiffuseurs : {2 : 0, 2.6: 0, 3.6: 0, 5: 0},
    nbDiffuseurs : 0,
    puissance : 0,
    totalGroupesExternes : {
      2.6 : 0, 
      3.6 : 0,
      5 : 0,
      5.2 : 0,
      6.8 : 0,
      8.6 : 0}
  };
  
  const getters = {
    pieces : state => { return state.pieces},
    totalDiffuseurs : state => { return state.totalDiffuseurs},
    nbDiffuseurs : state => { return state.nbDiffuseurs},
    puissance : state => { return Math.round(state.puissance * 100) / 100 }
  }
  
  const mutations = {
    ADD_PIECE(state) {
        state.pieces.push(new Piece("Pièce " + String(state.pieces.length + 1)))
    },
    DELETE_PIECE(state) {
        if(state.pieces.length > 0)
            state.pieces.pop()
    },
    CALCUL_TOTAL(state) {
        state.totalDiffuseurs = {2 : 0, 2.6: 0, 3.6: 0, 5: 0}
        state.nbDiffuseurs = 0;
        state.puissance = 0;
        state.pieces.forEach(piece => {
            for (var key in piece.diffuseurs) {
                if(piece.diffuseurs[key] != 0) {
                    state.totalDiffuseurs[key] += piece.diffuseurs[key]
                    state.nbDiffuseurs += piece.diffuseurs[key]
                    state.puissance += Math.round(Number(key)*100)/100
                }
                //console.log("key " + key + " has value " + piece.diffuseurs[key]);
            }
        }); 

        // state.totalDiffuseurs.forEach((value, name) => {
        //     console.log(`${value} x ${name}`)
        // } )
    }
  }
  
  const actions = {
    ajouterPiece(context) {
      context.commit("ADD_PIECE");
    },
    supprimerPiece(context) {
      context.commit("DELETE_PIECE");
    },
    calculTotalDiffuseurs(context) {
      context.commit("CALCUL_TOTAL");
    }
  }
  
  export default {
      state, getters, mutations, actions
  }