function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const state =  {
    client: {
      id: 0,
      idRegie : 0,
      nom : null,
      prenom :null,
      date_naissance:null,
      nom_conjoint : null,
      prenom_conjoint :null,
      date_naissance_conjoint: null,
      adresse :null,
      codePostal : null,
      ville :null,
      mobile:  null,
      telephone: null,
      email: null,
      revenu1: 0,
      nbPersonnes1: 0 ,
      revenu2: 0,
      nbPersonnes2: 0,
      revenu3: 0,
      nbPersonnes3: 0 ,   
      typeDossier: 7,
      zoneGeo: null,
      numero_mpr: null,
      categorieMPR: null,
      id_categorieMPR: null,
      id_categorieCEE: null,
      observation_dossier : null,
      declaration: {
        valeur : 'Ne jamais avoir réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat',
        signature : "",
        travaux : []
      }
    }
  };
  
  const getters = {
    idRegie : state => { return state.idRegie},
    client : state => { return state.client},
    totalRevenu : state => { return  Number(state.client.revenu1) + Number(state.client.revenu2) + Number(state.client.revenu3)},
    totalPersonnes : state => { return  Number(state.client.nbPersonnes1) + Number(state.client.nbPersonnes2) + Number(state.client.nbPersonnes3) },
    age : state => { return state.date_naissance ? calculateAge(state.date_naissance) : 0},
    ageConjoint : state => { return state.date_naissance_conjoint ? calculateAge(state.date_naissance_conjoint) : 0}
  }
  
  const mutations = {
    SET_CLIENT(state, client) {
        state.client = client
    },
    CLEAR_CLIENT(state) {
      state.client = null
    }
  }
  
  const actions = {
    setClient(context, client) {
      context.commit("SET_CLIENT", client);
    },
    clearClient(context) {
      context.commit("CLEAR_CLIENT");
    }
  }
  
  export default {
      state, getters, mutations, actions
  }