<template>
  <div>

      <b-form-row>       
            <b-col md="12">
              <h4>TARIFS ET CONDITIONS DE PAIEMENT</h4>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12">
            <b-card-group deck  class="mt-3 mb-3">
              <b-card border-variant="secondary" header="Total TTC" header-border-variant="secondary" align="center">
                <b-card-text>{{totalTTC | separators}}</b-card-text>
              </b-card>
              <b-card border-variant="secondary" header="Aides déduites" header-border-variant="secondary" align="center">
                <b-card-text>{{totalDeduit | separators}}</b-card-text>
              </b-card>
              <b-card border-variant="secondary" header="TOTAL NET A REGLER TTC" header-border-variant="secondary" align="center">
                  <b-card-text>{{ totalNetTTC | separators}}</b-card-text>
              </b-card>
            </b-card-group>
            </b-col>
          </b-form-row>
          <b-form-row> 
          <b-col md="12">
              <h6>ACOMPTE</h6>
            </b-col>
          </b-form-row> 
          <b-form-row >
            <b-col md="4" >
              <b-form-group label="à l'expiration du délai de retractation">
                <b-input-group append="€">
                  <b-form-input id="retractation" type="number" v-model="financement.acompteRetractation" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="à la livraison">
                <b-input-group append="€">
                  <b-form-input id="livraison" type="number" v-model="financement.acompteLivraison" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="à l'installation">
                <b-input-group append="€">
                  <b-form-input id="installation" type="number" v-model="financement.acompteInstallation" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12" class="mt-3 mb-3">
              <b-card border-variant="primary" header="Montant du Financement" header-bg-variant="primary" header-text-variant="white"  align="center">
                <b-card-text>
                  {{montantFinancement | separators}}
                </b-card-text>
              </b-card>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="4">
              <b-form-group id="maison-financement-grp" label="Maison de financement" label-for="maisonFin">
                <b-form-select id="maisonFin" v-model="financement.id_orga_financier" @change="handleOrganismeFinancement()">
                    <b-form-select-option value="23">Sofinco</b-form-select-option>
                    <b-form-select-option value="8727">Cetelem</b-form-select-option>
                    <b-form-select-option value="3480">Financo</b-form-select-option>
                    <b-form-select-option value="12">Domo Finance</b-form-select-option>
                    <b-form-select-option value="22">Projexio</b-form-select-option>
                    <b-form-select-option value="1">Comptant</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="!comptant">
              <b-form-group id="nb-echeance-grp" label="Nombre d'echéances" label-for="nb-echeance">
                <b-form-select id="nb-echeance" v-model="financement.nbEcheance" invalid-feedback="Merci de renseigner le nombre d'échéances" :state="financement.nbEcheance != null">
                    <b-form-select-option value="12">12</b-form-select-option>
                    <b-form-select-option value="24">24</b-form-select-option>
                    <b-form-select-option value="36">36</b-form-select-option>
                    <b-form-select-option value="48">48</b-form-select-option>
                    <b-form-select-option value="60">50</b-form-select-option>
                    <b-form-select-option value="72">72</b-form-select-option>
                    <b-form-select-option value="84">84</b-form-select-option>
                    <b-form-select-option value="96">96</b-form-select-option>
                    <b-form-select-option value="108">108</b-form-select-option>
                    <b-form-select-option value="120">120</b-form-select-option>
                    <b-form-select-option value="132">132</b-form-select-option>
                    <b-form-select-option value="144">144</b-form-select-option>
                    <b-form-select-option value="156">156</b-form-select-option>
                    <b-form-select-option value="168">168</b-form-select-option>
                    <b-form-select-option value="180">180</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="2" v-if="!comptant">
              <b-form-group label="Report 6 mois">
                <b-form-radio-group id="report" v-model="financement.report" name="radio-sub-component" :options="['Oui','Non']" invalid-feedback=
                "Merci de renseigner le report" :state="financement.report != null" @change="handleDuree()">
                </b-form-radio-group>
              </b-form-group>
            </b-col>          
            <b-col md="2">
              <b-button @click="getGrille()" class="mb-2" :disabled="loading" variant="primary" v-if="isOrganismeFinancier">Actualiser la grille <b-icon icon="arrow-clockwise" :animation="loading ? 'spin' :'' " font-scale="1"></b-icon></b-button>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="emprunteur-grp" label="Emprunteur" label-for="emprunteur" @select="handleCoemprunteur()">
                <b-form-select id="emprunteur" v-model="financement.emprunteur">
                    <b-form-select-option value="Monsieur">Monsieur</b-form-select-option>
                    <b-form-select-option value="Madame">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="age-grp" label="Age" label-for="age">
                <b-form-select id="age-emprunteur" v-model="financement.ade_emprunteur">
                    <b-form-select-option value="0.17">-60 ans </b-form-select-option>
                    <b-form-select-option value="0.21">+60 ans</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4" >
              <b-form-group id="coemprunteur-grp" label="Co-Emprunteur" label-for="co-emprunteur">
                <b-form-select id="co-emprunteur" v-model="financement.coemprunteur">
                    <b-form-select-option value="Non concerné">Non concerné</b-form-select-option>
                    <b-form-select-option value="Monsieur">Monsieur</b-form-select-option>
                    <b-form-select-option value="Madame">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="financement.coemprunteur != 'Non concerné'" >              
                <b-form-group id="age-coemprunteur-grp" label="Age Co-Emprunteur" label-for="age-co-emprunteur">
                <b-form-select id="age-co-emprunteur" v-model="financement.ade_coemprunteur">
                    <b-form-select-option value="0.17">-60 ans </b-form-select-option>
                    <b-form-select-option value="0.21">+60 ans</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="taeg-grp" label="TAEG" label-for="taeg">
                <b-input-group append="%">
                  <b-form-input id="taeg" type="number" v-model="financement.taeg" number :readonly="isOrganismeFinancier"></b-form-input>
                  <!-- <the-mask :mask="['#.##', '##.##']" :value="financement.taeg" v-model="financement.taeg" type="number" :masked=true placeholder="" class="form-control"></the-mask>-->
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="Taux débiteur" label-for="taux">
                <b-input-group append="%">
                  <b-form-input id="taux" type="number" v-model="financement.taux" number :readonly="isOrganismeFinancier"></b-form-input> 
                  <!--<the-mask :mask="['#.##', '##.##']" :value="financement.taux" v-model="financement.taux" type="number" :masked=true placeholder="" class="form-control"></the-mask>-->
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4" >
              <b-form-group label="Montant des échéances (hors assurance)" label-for="montant-echeance-ha">
                <b-input-group append="€">
                  <b-form-input id="montant-echeance-ha" type="number" v-model="financement.montantEcheanceHorsAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="Montant des échéances (avec assurance)" label-for="montant-echeance-aa">
                <b-input-group append="€">
                  <b-form-input id="montant-echeance-aa" type="number" v-model="financement.montantEcheanceAvecAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group> 
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="cout-total-ha-grp" label="Cout total du crédit (hors assurance)" label-for="cout-total-ha">
                <b-input-group append="€">
                  <b-form-input id="cout-total-ha" type="number" v-model="financement.coutTotalHorsAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="cout-total-grp" label="Cout total du crédit (avec assurance)" label-for="cout-total-aa">
                <b-input-group append="€">
                  <b-form-input id="cout-total-aa" type="number" v-model="financement.coutTotalAvecAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
              <h4>DELAIS</h4>
            </b-col>
          </b-form-row>

          <b-form-row>       
            <b-col md="12">
              <b-form-group label="L'installation des produits sera réalisée" v-slot="{ ariaDescribedby }" >
                <b-form-radio-group :aria-describedby="ariaDescribedby" v-model="financement.optionInstallation">
                  <b-form-radio value="option 1">Entre le 15ème et le 30ème jour suivant la livraison des produits (stockage des produits et transfert des risques chez le client)</b-form-radio>
                  <b-form-radio value="option 2">Le jour de la livraison des produits (cf article 4 des conditions générales de vente).</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>       
            <b-col md="12">
              <h4>OBSERVATIONS</h4>
            </b-col>
          </b-form-row>
          <b-form-row> 
            <b-col md="12">
              <b-form-textarea id="textarea-rows" rows="4" no-resize v-model="financement.observations"></b-form-textarea>
            </b-col>
          </b-form-row>


        </div>
</template>

<script>
import FinancementService from '../services/FinancementService'

export default {
  name: 'MFinancement',
  data: () => ({
    comptant : false,
    loading: false
  }),
  computed : {
    financement() {
      return this.$store.getters.financement
    },
    montantFinancement () { //montant_credit
      return this.totalNetTTC - (this.financement.acompteLivraison + this.financement.acompteInstallation + this.financement.acompteRetractation) 
    },
    totalTTC () {
      return this.$store.getters.totalTTC
    },
    totalNetTTC () {
      return this.$store.getters.totalTTC - this.$store.getters.totalDeduit;
    },
    duree () {
      return this.financement.nbEcheance - this.financement.dureeReport
    },
    totalDeduit() {
      return this.$store.getters.totalDeduit
    },
    user() {
      return this.$store.state.auth.user
    },
    isOrganismeFinancier() {
      return (this.financement.id_orga_financier == 3480 || this.financement.id_orga_financier == 22) &&
        ((this.financement.report != null && this.financement.nbEcheance != null && this.financement.emprunteur != 'Non concerné' && this.financement.ade_emprunteur != 'Non concerné') ||
        (this.financement.report != null && this.financement.nbEcheance != null && this.financement.emprunteur != 'Non concerné' && this.financement.ade_emprunteur != 'Non concerné' && this.financement.coemprunteur != null && this.financement.ade_coemprunteur != null))
    }
  },
  watch: {
    financement:{
      handler(){
        this.$store.dispatch("SetFinancement", this.financement)
      },
      deep: true,
    },
    montantFinancement:{
      handler(newValue){
        this.financement.montantCredit = newValue
      },
      deep: true,
    }
  },
  methods: {
    handleDuree() {
      if(this.financement.report == "Non") 
        this.financement.dureeReport = 0
      else
        this.financement.dureeReport = 6
    },
    handleCoemprunteur() {
      if(this.financement.emprunteur == 'Monsieur')
        this.financement.coemprunteur = 'Madame'
      
      if(this.financement.emprunteur == 'Madame')
        this.financement.coemprunteur = 'Monsieur'
    },
    handleOrganismeFinancement() {
      this.comptant = this.financement.id_orga_financier == 1
    },
    getGrille() {

      if(this.financement.id_orga_financier == 22) {
        FinancementService.GetGrilleProjexio(this.financement.ade_emprunteur, Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
        .then(result =>{
          this.financement.taeg = Number(result.data.taeg)
          this.financement.taux = Number(result.data.taea)
          this.financement.montantEcheanceHorsAssurance = Number(result.data.mensualiteSansAssurance)
          this.financement.montantEcheanceAvecAssurance = Number(result.data.mensualiteAvecAssurance) 
          this.financement.coutTotalHorsAssurance = Number(result.data.totalHorsAssurances)
          this.financement.coutTotalAvecAssurance = Number(result.data.totalAvecAssurances)
          
          if(this.financement.coemprunteur != 'Non concerné') {
            FinancementService.GetGrilleProjexio(this.financement.ade_coemprunteur, Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
            .then(result =>{
              this.financement.montantEcheanceAvecAssurance += (Number(result.data.mensualiteAvecAssurance) - Number(result.data.mensualiteSansAssurance))
              this.financement.coutTotalAvecAssurance += (Math.round(Number(result.data.totalAvecAssurances) -  Number(result.data.totalHorsAssurances),2))
            }).catch(error => {
              this.loading= false
              if(error.response.status == 500) {
                console.log("Error Grille")
              }
            })
          }          
          this.loading = false
        }).catch(error => {
          this.loading= false
          if(error.response.status == 500) {
            console.log("Error Grille")
          }
        })
        return
      }

      if(this.financement.report == null || this.financement.nbEcheance == null)
        return

      this.loading = true
      //id_organisation, montant_credit, nombre_mensualite, report
      FinancementService.GetGrille(this.financement.id_orga_financier, 
        Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
      //FinancementService.GetGrille(this.financement.id_orga_financier, 1000, this.financement.nbEcheance,this.financement.dureeReport)
      .then(result =>{
        this.financement.taeg = Number(result.data.TAEG)
        this.financement.taux = Number(result.data.taux_debiteur_fixe)
        this.financement.montantEcheanceHorsAssurance = Number(result.data.montant_mensualites_hors_ass)
        this.financement.montantEcheanceAvecAssurance = Number(result.data.montant_mensualites_hors_ass) + Number(result.data.cout_mensuel_ass_1)
        this.financement.coutTotalHorsAssurance = Number(result.data.montant_total_emprunteur_hors_ass)
        this.financement.coutTotalAvecAssurance = Number(result.data.montant_total_emprunteur_hors_ass) + Number(result.data.cout_total_ass_1)
        this.loading = false
      }).catch(error => {
        this.loading= false
        if(error.response.status == 500) {
          console.log("Error Grille")
        }
        
      })
    }
  }
}
</script>

<style>

</style>