export default class ModelVT {
  constructor() {
    this.validation_errors = []
    this.blocs = [
      {
        "id": 1,
        "title": "Questionnaire",
        "questions": [
          {
            "id": 0,
            "title": "le CERFA Mandataire ma prime renov’ a bien été uploadé sur le profil client",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"Niveau de revenu requis",
            "selected_parent_value": null,
            "sub_questions": null
          },
          {
            "id": 1,
            "title": "Niveau de revenu",
            "comment": null,
            "question_type": "select",
            "value_type": "select",
            "default_value": null,
            "list_values": [
              {
                "text": "Très modeste",
                "value": "Très modeste"
              },
              {
                "text": "Modeste",
                "value": "Modeste"
              },
              {
                "text": "Intermédiaire",
                "value": "Intermédiaire"
              },
              {
                "text": "Aisé",
                "value": "Aisé"
              }
            ],
            "value": null,
            "validation_error":"Niveau de revenu requis",
            "selected_parent_value": null,
            "sub_questions": null
          },
          {
            "id": 2,
            "title": "Ma prime renov",
            "comment": null,
            "question_type": "select",
            "value_type": "select",
            "default_value": null,
            "list_values": [
              {
                "text": "Niveau Bleu (si très modeste)",
                "value": "Niveau Bleu"
              },
              {
                "text": "Niveau Jaune (si modeste)",
                "value": "Niveau Jaune"
              },
              {
                "text": "Nivea Violet (si intermédiaire)",
                "value": "Nivea Violet"
              },
              {
                "text": "Niveau Rose (si aisé)",
                "value": "Niveau Rose"
              }
            ],
            "value": null,
            "validation_error":"Ma prime renov requis",
            "selected_parent_value": null,
            "sub_questions": null
          },
          {
            "id": 3,
            "title": "CEE",
            "comment": null,
            "question_type": "select",
            "value_type": "select",
            "default_value": null,
            "list_values": [
              {
                "text": "Standard",
                "value": "Standard"
              },
              {
                "text": "Bonifié",
                "value": "Bonifié"
              }
            ],
            "value": null,
            "validation_error":"CEE requis",
            "selected_parent_value": null,
            "sub_questions": []
          },
          {
            "id": 4,
            "title": "Coup de pouce",
            "comment": null,
            "question_type": "select",
            "value_type": "select",
            "default_value": null,
            "list_values": [
              {
                "text": "Standard",
                "value": "Standard"
              },
              {
                "text": "Bonifié",
                "value": "Bonifié"
              }
            ],
            "value": null,
            "validation_error":"Coup de pouce requis",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          {
            "id": 5,
            "title": "Si maison  avant travaux classée F ou G : Bonus Passoire énergétique ",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"Bonus Passoire énergétique requis",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          {
            "id": 7,
            "title": "Visite réalisée en présence du propriétaire",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"Visite réalisée en présence du propriétaire requis",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          {
            "id": 8,
            "title": "Température",
            "comment": null,
            "question_type": "inputText",
            "value_type": "number",
            "default_value": null,
            "list_values": [],
            "value": 15,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          {
            "id": 9,
            "title": "Météo lors de la visite",
            "comment": null,
            "question_type": "select",
            "value_type": "select",
            "default_value": null,
            "list_values": [
              {
                "text": "Dégagé",
                "value": "Niveau Bleu"
              },
              {
                "text": "Nuageux",
                "value": "Nuageux"
              },
              {
                "text": "Pluvieux",
                "value": "Pluvieux"
              },
              {
                "text": "Neigeux",
                "value": "Neigeux"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          // {
          //   "id": 10,
          //   "title": "A-t-il déjà realisé des travaux de rénovations énergétiques",
          //   "comment": null,
          //   "question_type": "radio",
          //   "value_type": "radio",
          //   "default_value": null,
          //   "list_values": [
          //     {
          //       "text": "Oui",
          //       "value": "Oui"
          //     },
          //     {
          //       "text": "Non",
          //       "value": "Non"
          //     }
          //   ],
          //   "value": null,
          //   "validation_error":"",
          //   "selected_parent_value": null,
          //   "sub_questions": [
          //     {
          //       "id": 10,
          //       "title": "Lesquels",
          //       "comment": null,
          //       "question_type": "checklist",
          //       "value_type": "checklist",
          //       "default_value": [],
          //       "list_values": [
          //         {
          //           "text": "PAC",
          //           "value": "PAC"
          //         },
          //         {
          //           "text": "Ballon Thermodynamique",
          //           "value": "Ballon Thermodynamique"
          //         },
          //         {
          //           "text": "Isolation Comble",
          //           "value": "Isolation Comble"
          //         },
          //         {
          //           "text": "Sous plancher",
          //           "value": "Sous plancher"
          //         },
          //         {
          //           "text": "Poele à granule",
          //           "value": "Poele à granule"
          //         },
          //         {
          //           "text": "Chaudière",
          //           "value": "Chaudière"
          //         },
          //         {
          //           "text": "VMC",
          //           "value": "VMC"
          //         },
          //         {
          //           "text": "Thermostat Programmable",
          //           "value": "Thermostat Programmable"
          //         }
          //       ],
          //       "value": [],
          //       "validation_error":"",
          //       "selected_parent_value": "Oui",
          //       "sub_questions": []
          //     },
          //     {
          //       "id": 11,
          //       "title": "Si autre, précisez",
          //       "comment": null,
          //       "question_type": "inputText",
          //       "value_type": "text",
          //       "default_value": null,
          //       "list_values": [],
          //       "value": null,
          //       "validation_error":"",
          //       "selected_parent_value": "Oui",
          //       "sub_questions": []
          //     }
          //   ]
          // },
          {
            "id": 100,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : PAC",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 101,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Ballon Thermodynamique",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 102,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Isolation Comble",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 103,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Sous plancher",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 104,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Poele à granule",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 105,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Chaudière",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 106,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : VMC",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 108,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Autre",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Précisez",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 107,
            "title": "A-t-il déjà realisé des travaux de rénovations énergétiques : Thermostat Programmable",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 1,
                "title": "Date de réalisation",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 11,
            "title": "La maison possède t'elle des panneaux photovoltaïques ",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": [],
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": [],
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 11,
                "title": "Quelle puissance ? (kwc)",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 12,
            "title": "A-t-il bénéficié des aides",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": [],
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 12,
                "title": "Lesquelles",
                "comment": null,
                "question_type": "checklist",
                "value_type": "checklist",
                "default_value": [],
                "list_values": [
                  {
                    "text": "Ma Primrenov",
                    "value": "Ma Primrenov"
                  },
                  {
                    "text": "CEE",
                    "value": "CEE"
                  },
                  {
                    "text": "Aide Régionale",
                    "value": "Aide Régionale"
                  }

                ],
                "value": [],
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 13,
            "title": "A-t-il bénéficié d'un prêt a taux zero (PTZ)",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          {
            "id": 14,
            "title": "La maison possède t-elle une véranda ?",
            "comment": null,
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [
              {
                "text": "Oui",
                "value": "Oui"
              },
              {
                "text": "Non",
                "value": "Non"
              }
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
              {
                "id": 0,
                "title": "est-elle chauffée et habitée ?",
                "comment": null,
                "question_type": "radio",
                "value_type": "radio",
                "default_value": null,
                "list_values": [
                  {
                    "text": "Oui",
                    "value": "Oui"
                  },
                  {
                    "text": "Non",
                    "value": "Non"
                  }
                ],
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              },
              {
                "id": 1,
                "title": "Hauteur (cm)",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              },
              {
                "id": 2,
                "title": "Longueur (cm)",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              },
              {
                "id": 3,
                "title": "Largeur (cm)",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              },
              {
                "id": 4,
                "title": "Surface de la partie maçonnée (m2)",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": null,
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              },
              {
                "id": 5,
                "title": "Types de vitrage",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": [],
                "list_values": [
                  {
                    "text": "Simple",
                    "value": "Simple"
                  },
                  {
                    "text": "Double récent",
                    "value": "Double récent"
                  },
                  {
                    "text": "Double ancien",
                    "value": "Double ancien"
                  }

                ],
                "value": null,
                "validation_error":"",
                "selected_parent_value": "Oui",
                "sub_questions": []
              }
            ]
          },
          {
            "id": 15,
            "title": "Quels équipements vont être changés par un produit neuf (gros electroménagers  et plaque de cuisson (type)… ) ",
            "comment": null,
            "question_type": "textarea",
            "value_type": "text",
            "default_value": null,
            "list_values": [
            ],
            "value": null,
            "selected_parent_value": null,
            "sub_questions": [
            ]
          }
          ,
          {
            "id": 16,
            "title": "Note pour le service ingénierie (Pb rencontrés, point de focus etc… ) ",
            "comment": null,
            "question_type": "textarea",
            "value_type": "text",
            "default_value": null,
            "list_values": [
            ],
            "value": null,
            "validation_error":"",
            "selected_parent_value": null,
            "sub_questions": [
            ]
          },
          // {
          //   "id": 17,
          //   "title": "Est-ce que le client accepte d’être recontacté par notre réseau d’installateurs qualifiés",
          //   "comment": null,
          //   "question_type": "radio",
          //   "value_type": "radio",
          //   "default_value": null,
          //   "list_values": [
          //     {
          //       "text": "Oui",
          //       "value": "Oui"
          //     },
          //     {
          //       "text": "Non",
          //       "value": "Non"
          //     }
          //   ],
          //   "value": null,
          //   "validation_error":"",
          //   "selected_parent_value": null,
          //   "sub_questions": [
          //   ]
          // }

        ]
      },

/*       {
        id: 8,
        title: "Photos",
        questions: [
          // {
          //   id:0,
          //   title :"Documents à fournir",
          //   comment : "documents",
          //   question_type: "section",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:1,
          //   title :"Contrat AMO",
          //   comment : "contratAMO",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:2,
          //   title :"Devis",
          //   comment : "devis",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:3,
          //   title :"Dernier avis d'imposition (toutes les pages)",
          //   comment : "avisImpot",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:4,
          //   title :"Si 2 déclarants, fournir le deuxième avis d'imposition (toutes les pages)",
          //   comment : "avisImpot2",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:5,
          //   title :"CNI recto",
          //   comment : "CNIrecto",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:6,
          //   title :"CNI verso",
          //   comment : "CNIverso",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:7,
          //   title :"Dernier bulletin de salaire",
          //   comment : "bulletinSalaire",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:8,
          //   title :"Livret de famille (si enfants à charge). Si pas d'enfant, un acte de mariage, de Pacs ou autre",
          //   comment : "livretFamille",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:9,
          //   title :"Dernière taxe foncière ou acte notarié",
          //   comment : "taxeFonciere",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:10,
          //   title :"Si Co-demandeur",
          //   comment : "codemandeur",
          //   question_type: "section",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:11,
          //   title :"CNI recto",
          //   comment : "codemandeurCNIrecto",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:12,
          //   title :"CNI verso",
          //   comment : "codemandeurCNIverso",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:13,
          //   title :"Bulletin de salaire",
          //   comment : "codemandeurBulletinSalaire",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:14,
          //   title :"Livret de famille",
          //   comment : "codemandeurLivret",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:15,
          //   title :"Si célibataire sans enfants, demander une attestation sur l'honneur",
          //   comment : "attestationCelibataire",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:16,
          //   title :"Si célibataire avec enfants, demander une attestation sur l'honneur et acte de naissance des enfants",
          //   comment : "attestationCelibataireAvecEnfants",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:17,
          //   title :"Si locataire",
          //   comment : "locataire",
          //   question_type: "section",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:18,
          //   title :"Bail de location",
          //   comment : "bail",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:19,
          //   title :"Attestation du propriétaire autorisant la demande et les travaux', value: 'Attestation du propriétaire autorisant la demande et les travaux",
          //   comment : "attestationProprietaire",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:20,
          //   title :"Si système de chauffage",
          //   comment : "sysChauffage",
          //   question_type: "section",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:21,
          //   title :"Diagnostic",
          //   comment : "diagnostic",
          //   question_type: "uploadDoc",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          // {
          //   id:"21",
          //   title :"Façades",
          //   comment : "facades",
          //   question_type: "section",
          //   value_type : null,
          //   list_values : null,
          //   value: null
          // },
          {
            id: "22",
            title: "Ouvrants",
            comment: "ouvrants",
            question_type: "uploadDoc",
            multi: true,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "23",
            title: "Façade Sud",
            comment: "fSud",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "24",
            title: "Façade Ouest",
            comment: "fOuest",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "25",
            title: "Façade Nord",
            comment: "fNord",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "26",
            title: "Facture Gaz",
            comment: "gaz",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "27",
            title: "Facture Electricité",
            comment: "elec",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "28",
            title: "Facture travaux ou ouvrants",
            comment: "sysChauffageMaisonM5",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "29",
            title: "Chaudiere",
            comment: "chaudiere",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "30",
            title: "Plaque chaudière",
            comment: "plaqueChaudiere",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "31",
            title: "Combles",
            comment: "photoIso2",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "32",
            title: "VMC + Bouche aeration",
            comment: "photoIso",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "33",
            title: "Thermostat (température de consigne + programmation horaire/réduit)",
            comment: "pTermo",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "34",
            title: "Radiateurs",
            comment: "factureIso",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "35",
            title: "Ballon",
            comment: "ballon",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "36",
            title: "Plaque du ballon",
            comment: "pBallon",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "37",
            title: "plan maison ",
            comment: "plan",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "38",
            title: "Climatisation",
            comment: "clim",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "39",
            title: "Plaque Climatisation (bloc ext)",
            comment: "pClim",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "40",
            title: "Insert / poele",
            comment: "insert",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "41",
            title: "Documents",
            comment: "docs",
            question_type: "section",
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "42",
            title: "Carte d'identité recto",
            comment: "CNIr",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "43",
            title: "Carte d'identité verso",
            comment: "CNIv",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "44",
            title: "DPE",
            comment: "dpe",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
          {
            id: "45",
            title: "Avis d'imposition",
            comment: "impot",
            question_type: "uploadDoc",
            multi: false,
            value_type: null,
            list_values: null,
            value: null
          },
        ],
      } */
    ]
  }



  get materiel() {
    console.log("get materiel")
    return this.blocs[3].questions[0].value ?
      (this.blocs[3].questions[0].value.includes("Chaudière actuelle")
        || this.blocs[3].questions[0].value.includes("Pompe à chaleur Air/Eau")
        ? this.blocs[3].questions[0].sub_questions[1].value
        : this.blocs[3].questions[0].sub_questions[2].value) : []
  }

  get libelleDossierPV() {
    return `${this.blocs[1].questions[9].value}PVX${this.blocs[1].questions[8].value}`
  }

  get qtePanneaux() {
    return this.blocs[1].questions[8].value
  }

  get puissancePanneaux() {
    return this.blocs[1].questions[9].value
  }

  isValid() {
    this.validation_errors = []
    this.blocs.forEach(bloc => {
      bloc.questions.forEach(question => {
        if(question.validation_error != null && question.validation_error != "" && question.value == null) {
          this.validation_errors.push(question.title)
        }
        if(question.sub_questions) {
          question.sub_questions.forEach(sub => {
            if(sub.validation_error != null && question.validation_error != "" && sub.selected_parent_value == question.value && (sub.value == null || sub.value.length == 0)) {
              this.validation_errors.push(question.title + ": " + sub.title)
            }
          })
        }
      })
    });

  }

  parse(vt) {
    if (vt === undefined || vt == '')
      return this.blocs;

    vt.forEach(bloc => {
      var currentBloc = this.blocs.filter(x => { return x.id == bloc.id })
      if (currentBloc.length > 0) {
        bloc.questions.forEach(question => {
          var currentQuestion = currentBloc[0].questions.filter(q => { return q.id == question.id })
          if (currentQuestion.length > 0) {
            currentQuestion[0].value = question.value
            if (question.sub_questions !== undefined) {
              question.sub_questions.forEach(sub => {
                var currentSub = currentQuestion[0].sub_questions.filter(s => { return s.id == sub.id })
                if (currentSub.length > 0) {
                  currentSub[0].value = sub.value
                }
              })
            }
          }
        })
      }
    });

    return this.blocs;
  }

}