import Api from '@/services/Api'
import authHeader from './Auth-header';

function CreateCustomer (customer) {
  console.log(customer)
  return Api().post('organisation/create/', customer, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:8530}})
}

function UpdateCustomer (customer) {
  console.log(customer)
  return Api().post(`organisation/update/${customer.id_organisation}`, customer, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:8530}})
}

function Customers (start, length) {
  return Api().get(`organisation/organisations/?start=${start}&length=${length}`, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:1}})
}

function Customer (organisation_id) {
  return Api().get(`organisation/organisation/${organisation_id}`, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:1}})
}

export default {
    CreateCustomer, Customers,Customer, UpdateCustomer
}