const state =  {
    documents: []
  };
  
  const getters = {
    documents : state => { return state.documents}
  }
  
  const mutations = {
    ADD_DOCUMENT(state, document) {
      //state.documents = state.documents.filter(p => p.id != document.id);
      state.documents.push(document)
    },
    DELETE_DOCUMENT(state, id) {
      state.documents = state.documents.filter(p => p.id != id);
    },
    CLEAR_DOCUMENTS(state) {
      state.documents = []
    },
    UPDATE_UPLOAD_PERCENTAGE(state, doc) {
      var index = state.documents.findIndex(p => p.id == doc.id);
      if(index >= 0) 
        state.documents[index].uploadPercentage = doc.uploadPercentage
    }
  }
  
  const actions = {
    ajouterDocument(context, document) {
      context.commit("ADD_DOCUMENT", document);
    },
    supprimerDocument(context, id) {
      context.commit("DELETE_DOCUMENT", id);
    },
    viderDocuments(context) {
      context.commit("CLEAR_DOCUMENTS");
    },
    majProgression(context, doc) {
      context.commit("UPDATE_UPLOAD_PERCENTAGE", doc);
    }
  }
  
  export default {
      state, getters, mutations, actions
  }