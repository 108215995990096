
export default class Produit {
    constructor(id, libelle, fabricantId, fabricantLibelle, ref, distributeur, famille, type, typeId, gamme, gammeId, carateristiques) {
      this.id = Number(id);
      this.libelle = libelle;
      this.fabricantId = fabricantId;
      this.fabricantLibelle = fabricantLibelle
      this.ref = ref;
      this.distributeur = distributeur
      this.famille = famille;
      this.type = type;
      this.typeId = typeId
      this.gamme = gamme;
      this.gammeId = gammeId;
      this.sousTotal = 0;
      this.nouveauSousTotal = 0;
      this.articles = []
      this.superficieMin = 0 
      this.superficieMax = 10000
      this.personneMin = 0
      this.personneMax = 999
      this.split = false
      this.duo = false
      this.bizone = false
      this.hauteur = 300
      this.qtePanneaux = 0
      this.puissancePanneaux = 330
      if(carateristiques !== undefined && carateristiques.length > 0) {
         var c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'SUPERFICIE MIN'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr) {
              this.superficieMin = Number(c[0].valeur_fr)
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'SUPERFICIE MAX'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr) {
              this.superficieMax = Number(c[0].valeur_fr)
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'PERSONNE MIN'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr) {
              this.personneMin = Number(c[0].valeur_fr)
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'PERSONNE MAX'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr) {
              this.personneMax = Number(c[0].valeur_fr)
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'SPLIT'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr.toUpperCase() == 'OUI') {
              this.split = true
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'BIZONE'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr.toUpperCase() == 'OUI') {
              this.bizone = true
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'DUO'}) 
         if(c.length > 0) {
            if(c[0].valeur_fr.toUpperCase() == 'OUI') {
              this.duo = true
            }
         }

         c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'HAUTEUR'}) 
         if(c.length > 0) {
          if(c[0].valeur_fr) {
            this.hauteur = Number(c[0].valeur_fr)
          }
        }

        c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'Nombre Unité'.toUpperCase()}) 
         if(c.length > 0) {
          if(c[0].valeur_fr) {
            this.qtePanneaux = Number(c[0].valeur_fr)
          }
        }

        c = carateristiques.filter(c => { return c.libelle_fr.toUpperCase() == 'Puissance nominale Unité'.toUpperCase()}) 
         if(c.length > 0) {
          if(c[0].valeur_fr) {
            this.puissancePanneaux = Number(c[0].valeur_fr)
          }
        }
      }

      this.carateristiques = carateristiques !== undefined && carateristiques.length > 0 ? carateristiques.map(c => { return {libelle : c.libelle_fr, valeur: c.valeur_fr}}) : []
    
    }
  }