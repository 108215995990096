<template>
  <b-container class="pt-2" fluid="md">
    <div class="d-flex flex-row mt-4  justify-content-start">
        <go-back/> <h3>Dossier</h3>
      </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <!-- Tabs with card integration -->
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset">
      <b-card no-body>
        <b-tabs v-model="tabIndex" small card pills>
          <b-tab title="Mes informations" :title-link-class="linkClass('info')" @click="selectedTab='info'">
          <b-form-row >
            <b-col md="6">
              <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom"></TextInput>
            </b-col>
            <b-col md="6">
              <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom"></TextInput>
            </b-col>
            <!-- <b-col style="align-self:center; padding-top:15px">
              <b-button variant="primary" @click="handleConjoint()" style="width:38px;height:38px">{{ existeConjoint ? "-" : "+"}}</b-button>
            </b-col> -->
          </b-form-row>
          <b-form-row>
            <b-col md="12">
               <b-form-group
                    label="Date de naissance"
                    label-for="dateNaissance"
                    invalid-feedback="La date de naissance est requise"
                    :state="dateNaissanceState"
                  >
                  <b-form-datepicker id="dateNaissance" placeholder="Choisir une date" locale="fr" v-model="informations.date_naissance" required :state="dateNaissanceState"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <TextInput id="nom_conjoint" type="text" label="Nom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.nom_conjoint"></TextInput>
            </b-col>
            <b-col md="6">
              <TextInput id="prenom_conjoint" type="text" label="Prénom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.prenom_conjoint"></TextInput>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>            
            <b-col md="3">
              <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.codePostal"></TextInput>             
            </b-col>
            <b-col md="6">
              <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.telephone"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.mobile"></TextInput>                 
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <b-button variant="primary" @click="updateCustomer()">Enregistrer</b-button>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12">
              <TextInput id="mpr" type="text" label="Numéro MPR: Immatriculation GES : 387931694_DLPF" :rules="{ required: false, min:3}" v-model="informations.numero_mpr"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12">
              <TextInput id="obs" type="textarea" label="Observation dossier" :rules="{ required: false, min:3}" v-model="informations.observation_dossier" rows="2"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
                <h3>Informations fiscales</h3>
            </b-col>
          </b-form-row >
          <b-form-row >
            <b-col md="12">
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th sticky-column></b-th>
                      <b-th>Foyer fiscal 1</b-th>
                      <b-th>Foyer fiscal 2</b-th>
                      <b-th>Foyer fiscal 3</b-th>
                      <b-th>Total</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th sticky-column>Revenu fiscal</b-th>
                      <b-td><TextInput id="revenu" type="number" label="" :rules="{ required: true}" v-model.number="informations.revenu1"></TextInput></b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu2"></TextInput> </b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu3"></TextInput></b-td>
                      <b-td>{{ totalRevenu}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column>Nombre de personnes</b-th>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" :rules="{ required: true}" v-model.number="informations.nbPersonnes1"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes2"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes3"></TextInput></b-td>
                      <b-td>{{ totalPersonnes}}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </b-col>
          </b-form-row>
            <b-form-row class="mb-2">
            <b-col md="12">
              <b-card-group deck  class="mt-3">
                <b-card border-variant="secondary" header="Catégorie MPR" :body-class="categorieColor" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieMPR}} - {{categorieColor}}</b-card-text>
                </b-card>

                <b-card border-variant="secondary" header="Catégorie CEE" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieCEE}}</b-card-text>
                </b-card>
              <b-card border-variant="secondary" header="Zone Géo" header-border-variant="secondary" align="center">
                  <b-card-text>
                     {{informations.zoneGeo}}
                  </b-card-text>
                </b-card>
            </b-card-group>
            </b-col>
          </b-form-row>
        </b-tab>
          <!-- <template #tabs-start>
            <b-form-select @change="onChange()" v-model="tabIndex">
              <b-form-select-option v-for="(bloc,index) in blocs" :key="bloc.id" :value="index">{{bloc.title}}</b-form-select-option>
                <template #first>
                  <b-form-select-option value="" disabled>-- Choisir --</b-form-select-option>
                </template>
            </b-form-select>
          </template> -->        
          <!--<b-tab  v-for="bloc in blocs" :key="bloc.id" :title="bloc.title" :title-link-class="linkClass(blocs.id)" @click="selectedTab=bloc.id"-->
            <!-- <BlocItem v-for="bloc in blocs" :key="bloc.id" :bloc="bloc" :selectedTab="selectedTab" @clicked="onClicked"></BlocItem> -->
          <!--/b-tab-->

          <b-tab title="Declaration" :title-link-class="linkClass('declaration')" @click="selectedTab='declaration'">
          <b-form-row >
            <b-col md="6">
              Je soussign(é) Mr Mme {{ informations.nom }} {{  informations.prenom }} né(e) le {{ informations.date_naissance }}
              demeurant au {{  informations.adresse }} {{  informations.codePostal }} {{  informations.ville }}
              <p>
                atteste par la présente :
                <br>
                <b-form-radio-group v-model="informations.declaration.valeur" name="declaration">
                  <b-form-radio value="Ne jamais avoir réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat">Ne jamais avoir réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat</b-form-radio>
                  <b-form-radio value="Avoir déjà réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat">Avoir déjà réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat</b-form-radio>
                </b-form-radio-group>
              </p>
              <p v-show='informations.declaration.valeur=="Avoir déjà réalisé de travaux de rénovation énergétique subventionnés par les aides de l’Etat"'>
                Si oui, type de travaux et date de réalisation :
                <object-list @updated="updated"></object-list>
              </p>
              
            </b-col>

            <b-col md="6">
              <b-card-body>
            <b-card-title>Signature</b-card-title>
            <VueSignaturePad
              id="signature1"
              width="100%"
              height="200px"
              ref="signatureClient"
              class="signature"
           />
           
           <b-button href="#" @click="undo('signatureClient')" variant="outline-primary">Effacer</b-button>
           <b-button href="#" @click="save('signatureClient')" variant="primary">Enregistrer</b-button>
          </b-card-body>
            </b-col>
          </b-form-row>
        </b-tab>

          <b-tab v-if="isLoaded" title="Dossier" :title-link-class="linkClass('dossier')" @click="selectedTab='dossier'">
            <b-form-row>
              <b-col md="12">
                <CreationDossier :informations="informations" :itemRequired="true" @onCreated="resetVT" @onConfirmed="onDossierConfirmed"/>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <facturation :idDossier="idDossier" :observations="observations" ></facturation>
              </b-col>
            </b-form-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form>
    </validation-observer>
  </b-container>
</template>

<script>

//import DossierService from '../services/DossierService'
import ClientService from '../services/ClientService'
//import BlocItem from '../components/BlocItem'
import TextInput from '../components/TextInput'
import GoBack from '../components/GoBack'
import CreationDossier from '../components/CreationDossier'
import AidesService from '../services/AideService'
import Facturation from '../components/Facturation'
//import ModelVT from '../models/modelVT'
//import SingleFileUpload from '../components/SingleFileUpload.vue'
import ObjectList from '../components/ObjectList.vue';


export default {
  name: 'VT',
  components: {
    TextInput,
    CreationDossier,
    //BlocItem,
    GoBack,
    Facturation,
    ObjectList
    //SingleFileUpload
  },
  data: () => ({
      index: 1,
      tabIndex: 0,   
      selectedTab: "info",
      loading : false,
      show: true,
      blocs : [],
      existeConjoint: false,
      editMode: false,
      isLoaded: false,
      categorieColor : "",
      idDossier : 0,
      observations: "",
      dateNaissanceState: null
  }),
  mounted(){
    console.log(this.$route.params)
    this.$store.dispatch('getPacks', this.id_organisation).then(() => {

    })

    this.$store.dispatch('GetProgrammeAide', 1)
    
    if(this.$route.params && this.$route.params.idOrganisation) {
      ClientService.Customer(this.$route.params.idOrganisation).then(response => {
        console.log(response.data.data_orga)
        const data = response.data.data_orga

       this.informations.id = data.id_organisation,
       this.informations.nom = data.nom_contact,
       this.informations.prenom = data.prenom_contact
       this.informations.nom_conjoint = data.liv_contact,
       this.informations.prenom_conjoint = data.liv_contact_prenom,
       this.informations.date_naissance = data.contact_date_naissance,
       this.informations.adresse = data.adresse,
       this.informations.codePostal = data.code_postal,
       this.informations.ville =data.ville,
       this.informations.mobile=  data.num_tel_1,
       this.informations.telephone= data.num_tel_2,
       this.informations.email= data.email,
       this.informations.revenu1= 0,
       this.informations.nbPersonnes1= 0 ,
       this.informations.revenu2 = 0,
       this.informations.nbPersonnes2 = 0,
       this.informations.revenu3 = 0,
       this.informations.nbPersonnes3 = 0 ,   
       this.informations.zoneGeo =null,
       this.informations.categorieMPR = null,
       this.informations.id_categorieMPR = null,
       this.informations.id_categorieCEE = null,
       this.informations.numero_mpr= null,
       this.informations.observation_dossier=null
      this.$store.dispatch("setClient", this.informations)



      }).catch(error => {
      this.loading = false
      this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
    })
    }
    this.isLoaded = true
  },
  watch: {
     informations: {
      handler(){
        this.$store.dispatch("setClient", this.informations)
      },
      deep: true
    },
    totalRevenu : {
      handler() {
          this.categorieZoneGeo()
      }
    },
    totalPersonnes : {
      handler() {
          this.categorieZoneGeo()
      }
   },
  },
  computed: {
    informations () {
      return this.$store.getters.client
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    },
    id_user_membre() {
      return this.$store.getters.user.id_user_membre
    }
  },
  methods: {
    undo(pad) {
        this.$refs[pad].undoSignature();

        
      },
      save(pad) {
        const signature = this.$refs[pad].saveSignature();

          if(signature.isEmpty) {
            this.msgBox(`Signature requise`, "Erreur", "danger")
            return 
          }
          console.log(signature.isEmpty);
          console.log(signature.data);
          this.informations.declaration.signature = signature.data
          this.$store.dispatch("setClient", this.informations)
          this.msgBox(`Signature enregistrée`, "Signature client", "success")
      },
      msgBox(message, titre, variant) {
        return this.$bvModal.msgBoxOk(message, {
              title: titre,
              okVariant: variant,
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
      },
    updated(objectArray) {
      this.informations.declaration.travaux = objectArray
      this.$store.dispatch("setClient", this.informations)
    },
    newCustomer(numTransaction) {
      return {
        id_organisation : this.$route.params.idOrganisation,
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
         typeop : 1,
         nom : this.informations.nom,
         prenom : this.informations.prenom,
         adresse : this.informations.adresse,
         codePostal : this.informations.codePostal,
         ville : this.informations.ville,
         id_pays : 1,
         mobile: this.informations.mobile,
         telephone: this.informations.telephone,
         email : this.informations.email,
         contact_date_naissance : this.informations.date_naissance,
  
        //Conjoint
         liv_raison_sociale : "",
         liv_adresse : this.informations.adresse,
         liv_code_postal : this.informations.codePostal,
         liv_ville : this.informations.ville,
         liv_fk_pays : 1,
         liv_contact : this.informations.nom_conjoint,
         liv_contact_prenom : this.informations.prenom_conjoint,
         liv_num_tel_1 : this.informations.mobile,
         liv_num_tel_2 : this.informations.telephone,
         liv_email : this.informations.email,
      }
    },
    async updateCustomer() {
      var numTransaction = Math.floor(Math.random() * 100000) + 1;
        var customer = this.newCustomer(numTransaction)
        let result = null
        try {
          result = await ClientService.UpdateCustomer(customer)
          // Creation du dossier
          if(result.status =="NOK") {
            console.log(result.message_specifique)
            if(result.error_number == 1062) {
              // le client existe deja
              
              // message : 
              this.$bvModal.msgBoxOk(`Erreur : Le client existe déjà`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
              return       
            }
            else {
              this.message = result.message_specifique
              return
            }
          }
          else {
            this.$bvModal.msgBoxOk('Client enregistré avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          }




        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le client : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
      

    },
    onDossierConfirmed(observations) {
      console.log(observations)
      this.observations = observations
    },
    updateTabindex(index) {
      this.tabIndex = index
    },
      etat(id) {
        var str = ''
        switch (id) {
          case '1':
            str = 'success'
            break
          case '2':
            str = 'danger'
            break
          case '3':
            str = 'light'
            break
          case '0':
            str = 'warning'
            break
          default:
            str = ''
            break;
        }
        return str
      },
    categorieZoneGeo() {
      var data = {
          code_app : "APP_COMMERCIALE",
          numTransaction : Math.floor(Math.random() * 100000) + 1,
          id_user_membre : this.id_user_membre,
          id_programme_aide : 1,
          revenu_fiscal : this.totalRevenu,
          nombre_personne : this.totalPersonnes,
          cp : this.informations.codePostal ? this.informations.codePostal.substring(0,2) : "0",
          fk_organisation : "3651"
        }
        
        AidesService.GetCategorieClient(data)
        .then(response => {
  
          if(response.data.statut == "OK") {
            this.informations.categorieMPR = response.data.nom_categorieMPR
            this.informations.id_categorieMPR = response.data.id_categorieMPR
            this.informations.categorieCEE = response.data.nom_categorieCEE
            this.informations.id_categorieCEE = response.data.id_categorieCEE
            this.informations.zoneGeo = response.data.zone_geo

            switch (this.informations.categorieMPR) {
              case "Très modestes":
                this.categorieColor = "Bleue"
                break;
              case "Modestes":
                this.categorieColor = "Jaune"
                break;
              case "Intermédiaires":
                this.categorieColor = "Violet"
                break;
              case "Les Plus Elevés":
                this.categorieColor = "Rose"
                break;
                                    
              default:
                break;
            }
          }
          
        })
        .catch(error => {
          console.log(error)
        })
    },
    linkClass(tab) {
      if (this.selectedTab === tab) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-primary']
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT(idDossier) {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.$store.dispatch("viderDocuments")
      this.idDossier = idDossier
      //this.$router.push('/clients');
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    handleConjoint() {
      this.existeConjoint =! this.existeConjoint
      this.informations.nom_conjoint = ''
      this.informations.prenom_conjoint = ''
    },
    onClicked(val) {
      console.log(val)
      this.selectedTab = val
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
legend {
  font-size: 16px;
}

p {
  padding: 10px;
}
.signature {
  margin-bottom: 13px;
  border: 1px solid;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
