export default class Aide {
    constructor(id, code, grille,titre, prime,categorie, deductible, organisations) {
      this.id = id;
      this.code  = code;
      this.grille  =grille;
      this.titre = titre;
      this.prime  = prime;
      this.categorie = categorie;
      this.deductible = deductible;
      this.organisations = organisations;
      this.oblige = null
    }
  }